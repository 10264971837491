import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import myApi from "../../../network/axios";
import { Link } from 'react-router-dom';

const CheckStripeInfoModal = ({ checkStripe, setCheckStripe, setStripeAccountStatus, stripeAccountStatus }) => {

    const [loading, setLoading] = useState(true); // Set initial loading to true

    const getStripeAccountInfo = async () => {
        setLoading(true);
        try {
            const response = await myApi.get(
                `${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`
            );
            setStripeAccountStatus(true);
        } catch (error) {
            console.log("error", error);
            setStripeAccountStatus(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getStripeAccountInfo();
    }, []);

    return (
        <div>
            {!loading && stripeAccountStatus === false && ( // Show the modal only if loading is false and Stripe is not attached
                <Modal
                    title="Oppsss,"
                    centered
                    open={checkStripe}
                    onCancel={() => setCheckStripe(false)} // Close modal when canceled
                    footer={[
                        <Button style={{ padding: "0px 30px" }} key="submit" type="primary">
                            <Link to='/Integration/LinkStripe'>Setup Stripe</Link>
                        </Button>
                    ]}
                >
                    <p>Missing Stripe Setup. <br />
                        No payment setup found.Please add your Stripe account to accept booking payments.
                    </p>
                </Modal>
            )}
        </div>
    );
}

export default CheckStripeInfoModal;