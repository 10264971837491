import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
// UI's
import { Typography, Space, Button, Row, Col, Grid } from "antd";
import { CaretRightOutlined, SaveOutlined, EyeOutlined, LinkOutlined, FormOutlined } from "@ant-design/icons";
import { generateCode } from "../../services/integrations";
import { draftData } from "../../services/integrations";
import usePopup from "../../common/MessageModal/usePopup";
// CSS
import "./integrationStyles.scss";

import Contracts from "./comps/Contracts";
import EventCalender from "./comps/EventCalender";
import ImageUploader from "./comps/ImageUploader";
import Payments from "./comps/Payments";
import PricingModel from "./comps/PricingModel";
import RoomDetails from "./comps/RoomDetails";
import Services from "./comps/Services";
import ShowCode from "./comps/ShowCode";
import StartGuide from "./comps/StartGuide";
import UserPackages from "./comps/UserPackages";
import VenueDetails from "./comps/VenueDetails";
import WelcomeMessageModal from "./comps/WelcomeMessageModal";
import CheckStripeInfoModal from "./comps/CheckStripeInfoModal";
import VenueModal from "./comps/VenueModal";


const { useBreakpoint } = Grid;
const Integrations = () => {
  const screens = useBreakpoint();
  const popup = usePopup();
  const { Title } = Typography;
  const selectedVenue = useSelector((state) => state?.venues?.selectedVenue);

  const [screenIndex, setScreenIndex] = useState(0)
  const [errors, setErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [generatedCode, setGeneratedCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const [terms, setTerms] = useState({ title: "", terms_html: "" });
  const [venue, setVenueData] = useState([]);
  const [venueDetails, setVenueDetails] = useState({});
  const [roomDetails, setRoomsDetails] = useState({})
  const [selectedPackages, setSelectedPackages] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [enableServices, setEnableServices] = useState(true)
  const [enablePackages, setEnablePackages] = useState(true)
  const [pricingModel, setPricingModel] = useState({});
  const [blockedDates, setBlockedDates] = useState([]);
  const [payments, setPayments] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [selectedVenueImage, setSelectedVenueImage] = useState(null);
  const [stripeAccountStatus, setStripeAccountStatus] = useState(false);

  const [welcomeModal, setWelcomeModal] = useState(true);
  const [checkStripe, setCheckStripe] = useState(false);
  const [venueMissingModal, setVenueMissingModal] = useState(false);

  const [draftLoading, setDraftLoading] = useState(false)
  const [draftDetails, setDraftDetails] = useState([])
  const [newRoom, setNewRoom] = useState(false);

  const venueName = selectedVenue?.name === undefined ? selectedVenue?.label : selectedVenue?.name;

  useEffect(() => {
    if (selectedVenue) {
      getDraftData(selectedVenue?.id)
    }
  }, [selectedVenue])

  const getDraftData = async (id) => {
    setDraftLoading(true)
    try {
      const res = await draftData(id);
      setDraftDetails(res?.data?.data);
      console.log("draft details", res?.data?.data)
    } catch (error) {
      setDraftDetails([])
      console.log(error)
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log('Redirecting to server error page...');
      } else {
        // toast.error('Oops...', error?.response?.data?.detail);
      }
    } finally {
      setDraftLoading(false);
    }
  }

  const validateFields = () => {
    const errors = {};
    if (venueDetails?.phone?.length <= 13 || !venueDetails?.email) {
      errors.venue = "Please fill in the required fields";
      toast.error("Please add correct email and phone");
    }
    if (!roomDetails?.guestCount_0 || !roomDetails?.Price_0) {
      errors.roomDetails = "Please fill in the required fields";
      toast.error("Please fill all room details");
    }
    // if (!selectedPackages?.length) {
    //   errors.packages = "Please select at least one package.";
    // }
    // if (!selectedServices?.length) {
    //   errors.services = "Please select at least one service.";
    // }
    if (pricingModel?.packages === undefined || pricingModel?.services === undefined || pricingModel?.rooms === undefined) {
      errors.pricingModel = "Please select all the pricing model details.";
    }
    if (selectedVenueImage === null) {
      errors.images = "Please Select any venue Image"
      toast.error("Please Select any venue Image");
    }
    if (profileImage === null) {
      errors.images = "Please Upload Company Logo"
      toast.error("Please Upload Company Logo");
    }
    if (!terms?.title) {
      errors.terms = { ...errors.terms, title: "This field is required" };
    }
    if (!terms?.terms_html) {
      errors.terms = { ...errors.terms, terms_html: "This field is required" };
      toast.error("Please select terms and conditions");
    }
    if (stripeAccountStatus === false) {
      errors.stripe = "Please link stripe"
      toast.error("Please link your stripe");

      // setCheckStripe(true)
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const generateIframeCode = async (type) => {
    if (type !== "Draft") {
      const hasErrors = validateFields();
      if (hasErrors) {
        return;
      }
    }
  
    setLoading(true);
  
    const generated_url = `${window.location.origin}/booking/${venueName}-${selectedVenue?.id}`;
    try {
      const payload = {
        reference_name: "PlanSpace website integration",
        packages: enablePackages ? selectedPackages.join(",") : "",
        addons: enableServices ? selectedServices.join(",") : "",
        terms: JSON.stringify(terms),
        location: selectedVenue?.id,
        status: type === "Draft" ? "draft" : "activated",
        generated_url: generated_url.toLowerCase().replace(/ /g, "-"),
        packages_pm: pricingModel?.packages,
        rooms_pm: pricingModel?.rooms,
        addons_pm: pricingModel?.services,
        email: venueDetails?.email,
        phone: venueDetails?.phone,
        about_us_text: venueDetails?.about_us_text,
        banner: selectedVenueImage?.image || "",
        company_logo: profileImage,
        blocked_days: blockedDates,
        payment_terms: payments,
      };
  
      if (newRoom) {
        if (typeof roomDetails === "object") {
          payload.room = {
            name: roomDetails?.roomName_0,
            max_guests: roomDetails?.guestCount_0,
            price: roomDetails?.Price_0,
          };
        } else if (Array.isArray(roomDetails)) {
          payload.room = {
            name: roomDetails?.roomName_0,
            max_guests: roomDetails?.guestCount_0,
            price: roomDetails?.Price_0,
          };
        } else {
          payload.rooms = roomDetails;
        }
      }
  
      let formData = new FormData();
      for (let key in payload) {
        if (key === "payment_terms") {
          payments.forEach((item, index) => {
            formData.append(`payment_terms[${index}][payment_date]`, item.payment_date || "");
            formData.append(`payment_terms[${index}][is_partials_allowed]`, item.is_partials_allowed);
            formData.append(`payment_terms[${index}][percentage]`, item.percentage);
          });
        } else if (key === "room") {
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      }
  
      console.log(payload);
  
      // API Call
      const res = await generateCode(formData);
      setGeneratedCode(res?.data?.data);
  
      if (type === "preview") {
        window.open(res?.data?.data?.generated_url, "_blank");
      } else {
        setShowModal(type);
      }
  
      if (type !== "Draft") {
        popup.success(res?.data?.message);
      }
    } catch (error) {
      console.error("Error generating iframe code:", error);
      toast.error(error?.response?.data?.message || "An error occurred while generating the iframe code.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    setGeneratedCode(null);
  }, [venue, selectedPackages, payments, terms]);

  const navigateNextPageInMobile = () => {
    if (screenIndex === 0) {
      if (venueDetails?.phone?.length <= 13 || !venueDetails?.email) {
        toast.error("Please fill all venue details");
      } else {
        setScreenIndex(screenIndex + 1)
      }
    }
    if (screenIndex === 1) {
      if (!roomDetails?.guestCount_0 || !roomDetails?.Price_0) {
        toast.error("Please fill all room details");
      }
      else {
        setScreenIndex(screenIndex + 1)
      }
    }
    if (screenIndex === 2) {
      setScreenIndex(screenIndex + 1)
    }
    if (screenIndex === 3) {
      if (selectedVenueImage === null || profileImage === null) {
        toast.error("Please select venue and profile image");
      }
      else {
        setScreenIndex(screenIndex + 1)
      }
    }
    if (screenIndex === 4) {
      if (!terms?.terms_html) {
        toast.error("Please select terms and conditions");
      }
      else {
        setScreenIndex(screenIndex + 1)
      }
    }
  }

  const navigatePreviousPageInMobile = () => {
    setScreenIndex(screenIndex - 1)
  }
  const closeWelcomeModal = () => {
    setWelcomeModal((prev) => !prev)
    localStorage.setItem("welcome_modal_closed", "true");
  }

  const closeVenueModal = () => {
    setVenueMissingModal((prev) => !prev)
    setCheckStripe(true)
  }

  const closeStripeModal = () => {
    setCheckStripe((prev) => !prev)
  }
  useEffect(() => {
    const handleModals = () => {
        const hasClosedWelcomeModal = localStorage.getItem("welcome_modal_closed");
        if (hasClosedWelcomeModal === "true") {
            setWelcomeModal(false);
        } else {
            setWelcomeModal(true);
        }
        setTimeout(() => {
            if (venue && venue?.length === 0) {
                setVenueMissingModal(true);
            } else {
                setVenueMissingModal(false);
                setCheckStripe(true);
            }
        }, 3000);

        setTimeout(() => {
          setCheckStripe(false);
        }, 15000);
    };

    handleModals();
    return () => {
        clearTimeout();
    };
}, [venue]);

  

 

  return (
    <div>

      <div className="main-integration-styles">
        <div className="booking-head">
          <Space>
            <Title className="main-head">Venue Booking Tool</Title>
          </Space>
          <div className="text-alignment">
            <div className="events-details" style={{ flexWrap: "wrap", }}>
              <p className="event-head" style={{ color: "#667085", }}>
                Integrations
              </p>
              <p className="event-head" style={{ color: "#667085", }}>
                <CaretRightOutlined /> Booking Tool Setups
              </p>
              <p className="event-head" style={{ color: "#0B98D2", marginTop: screens.xs ? venueName?.length > 15 ? "8px" : "0px" : "" }}>
                <CaretRightOutlined />
                {venueName}
              </p>
            </div>
            <div className="buttons-integration">
              <Button
                style={{ marginRight: "5px" }}
                className="cancel-button"
                icon={<FormOutlined />}
                onClick={() => generateIframeCode("Draft")}
                loading={loading}
              >
                {/* Save as draft */}
                {screens.xs ? "Draft" : "Save as draft"}
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                className="save-button"
                icon={<SaveOutlined />}
                onClick={() => {
                  if (generatedCode) {
                    setShowModal("Integrations Code");
                  } else {
                    generateIframeCode("Integrations Code");
                  }
                }}
                loading={loading}
              >
                Save
                {/* {screens.xs ? "" : "Save"} */}
              </Button>
              <Button
                style={{ marginRight: "5px" }}
                className="save-button"
                icon={<EyeOutlined />}
                loading={loading}
                onClick={() => {
                  if (generatedCode) {
                    window.open(generatedCode?.generated_url, "_blank");
                  } else {
                    generateIframeCode("preview");
                  }
                }}
              >
                Preview
                {/* {screens.xs ? "" : "Preview"} */}
              </Button>
              <Button
                type="primary"
                className="send-button"
                icon={<LinkOutlined />}
                onClick={() => {
                  if (generatedCode) {
                    setShowModal("Send by Email");
                  } else {
                    generateIframeCode("Send by Email");
                  }
                }}
                loading={loading}
              >
                {/* Send by Email */}
                {screens.xs ? "Email" : "Send by Email"}
              </Button>
            </div>
          </div>
        </div>

        {
          draftLoading === true ? (
            <Loader minHeight={600} />
          ) : (
            <>
              <div className="form-integration">
                <Row gutter={[16, 0]}>
                  <Col xs={24} sm={24} md={18} lg={18} xl={18}>

                    {
                      screens.xs ? (
                        <>
                          {screenIndex === 0 && (
                            <VenueDetails
                              draftDetails={draftDetails}
                              error={errors?.venue}
                              setErrors={setErrors}
                              setVenueDetails={setVenueDetails}
                              venueDetails={venueDetails}
                              venue={venue}
                              setVenue={setVenueData}
                              selectedVenue={selectedVenue}
                              venueMissingModal={venueMissingModal}
                            />
                          )}

                          {screenIndex === 0 && (
                            <PricingModel
                              setPricingModel={setPricingModel}
                              draftDetails={draftDetails}
                            />
                          )}

                          {screenIndex === 1 && (
                            <RoomDetails
                              error={errors?.roomDetails}
                              setErrors={setErrors}
                              setRoomsDetails={setRoomsDetails}
                              selectedVenue={selectedVenue}
                              pricingModel={pricingModel?.rooms}
                              roomDetails={roomDetails}
                            />
                          )}

                          {screenIndex === 1 && (
                            <Services
                              draftDetails={draftDetails}
                              error={errors?.services}
                              setErrors={setErrors}
                              setSelectedServices={setSelectedServices}
                              selectedServices={selectedServices}
                              setEnableServices={setEnableServices}
                              enableServices={enableServices}
                              pricingModel={pricingModel?.services}
                            />
                          )}

                          {screenIndex === 2 && (
                            <UserPackages
                              draftDetails={draftDetails}
                              error={errors?.packages}
                              setErrors={setErrors}
                              venue={selectedVenue}
                              setSelectedPackages={setSelectedPackages}
                              selectedPackages={selectedPackages}
                              setEnablePackages={setEnablePackages}
                              enablePackages={enablePackages}
                              pricingModel={pricingModel?.packages}
                            />
                          )}

                          {screenIndex === 2 && (
                            <EventCalender
                              draftDetails={draftDetails}
                              location={selectedVenue}
                              blockedDates={blockedDates}
                              setBlockedDates={setBlockedDates}
                            />
                          )}

                          {screenIndex === 3 && (
                            <Payments createdPayments={setPayments} draftDetails={draftDetails} />
                          )}

                          {screenIndex === 3 && (
                            <ImageUploader
                              selectedVenue={selectedVenue}
                              draftDetails={draftDetails}
                              venue={venue}
                              selectedVenueImage={selectedVenueImage}
                              profileImage={profileImage}
                              setProfileImage={setProfileImage}
                              setSelectedVenueImage={setSelectedVenueImage}
                              setErrors={setErrors}
                              error={errors?.images}
                            />
                          )}

                          {screenIndex === 4 && (
                            <Contracts
                              draftDetails={draftDetails}
                              error={errors?.terms}
                              setErrors={setErrors}
                              terms={terms}
                              setTerms={setTerms}
                            />
                          )}

                        </>
                      ) : (
                        <>

                          <VenueDetails
                            draftDetails={draftDetails}
                            error={errors?.venue}
                            setErrors={setErrors}
                            setVenueDetails={setVenueDetails}
                            venueDetails={venueDetails}
                            venue={venue}
                            setVenue={setVenueData}
                            selectedVenue={selectedVenue}
                            venueMissingModal={venueMissingModal}
                          />

                          <PricingModel
                            setPricingModel={setPricingModel}
                            draftDetails={draftDetails}
                          />

                          <RoomDetails
                            error={errors?.roomDetails}
                            setErrors={setErrors}
                            setRoomsDetails={setRoomsDetails}
                            selectedVenue={selectedVenue}
                            pricingModel={pricingModel?.rooms}
                            roomDetails={roomDetails}
                            setNewRoom={setNewRoom}
                          />

                          <Services
                            draftDetails={draftDetails}
                            error={errors?.services}
                            setErrors={setErrors}
                            setSelectedServices={setSelectedServices}
                            selectedServices={selectedServices}
                            setEnableServices={setEnableServices}
                            enableServices={enableServices}
                            pricingModel={pricingModel?.services}
                          />

                          <UserPackages
                            draftDetails={draftDetails}
                            error={errors?.packages}
                            setErrors={setErrors}
                            venue={selectedVenue}
                            setSelectedPackages={setSelectedPackages}
                            selectedPackages={selectedPackages}
                            setEnablePackages={setEnablePackages}
                            enablePackages={enablePackages}
                            pricingModel={pricingModel?.packages}
                          />

                          <EventCalender
                            draftDetails={draftDetails}
                            location={selectedVenue}
                            blockedDates={blockedDates}
                            setBlockedDates={setBlockedDates}
                          />

                          <Payments createdPayments={setPayments} draftDetails={draftDetails} />

                          <ImageUploader
                            selectedVenue={selectedVenue}
                            draftDetails={draftDetails}
                            venue={venue}
                            selectedVenueImage={selectedVenueImage}
                            profileImage={profileImage}
                            setProfileImage={setProfileImage}
                            setSelectedVenueImage={setSelectedVenueImage}
                            setErrors={setErrors}
                            error={errors?.images}
                          />

                          <Contracts
                            draftDetails={draftDetails}
                            error={errors?.terms}
                            setErrors={setErrors}
                            terms={terms}
                            setTerms={setTerms}
                          />

                        </>

                      )
                    }

                  </Col>

                  {
                    screens.xs ? null : (
                      <>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <StartGuide
                            venueDetails={venueDetails}
                            terms={terms}
                            servicesError={errors?.services}
                            packagesError={errors?.packages}
                            pricingModel={pricingModel}
                            roomDetails={roomDetails}
                            profileImage={profileImage}
                            selectedVenueImage={selectedVenueImage}
                            stripeAccountStatus={stripeAccountStatus}
                            enableServices={enableServices}
                            enablePackages={enablePackages}
                            selectedServices={selectedServices}
                            selectedPackages={selectedPackages}
                          />
                        </Col>
                      </>
                    )
                  }

                  {
                    screens.xs ?
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 10px 10px 0px" }}>
                          {
                            screenIndex !== 0 ?
                              <Button className="button-terms" size="large" onClick={navigatePreviousPageInMobile}>Go Back</Button>
                              : <div />
                          }
                          {
                            screenIndex < 4 ?
                              <Button className="button-terms" size="large" onClick={navigateNextPageInMobile}>Move To Next</Button> : null
                          }
                        </div>
                      </Col>
                      : null
                  }

                </Row>
              </div>
            </>
          )}


        {welcomeModal && <WelcomeMessageModal welcomeModal={welcomeModal} closeWelcomeModal={closeWelcomeModal} />}

        {checkStripe && <CheckStripeInfoModal checkStripe={checkStripe} setCheckStripe={closeStripeModal} stripeAccountStatus={stripeAccountStatus} setStripeAccountStatus={setStripeAccountStatus} />}

        {venueMissingModal && venue.length === 0 && <VenueModal venueMissingModal={venueMissingModal} setVenueMissingModal={closeVenueModal} />}

        <ShowCode
          open={showModal}
          setOpen={setShowModal}
          handelClose={() => setShowModal(false)}
          generatedCode={generatedCode}
        />

      </div>

    </div>

  );
};

export default Integrations;
