import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { getServices } from "../../../services/services";
import usePopup from "../../../common/MessageModal/usePopup";
import Loader from "../../../common/Loader";
import templateImage from "../../../assets/background.jpg";

const { useBreakpoint } = Grid;

const Services = ({ draftDetails, selectedServices, setSelectedServices, error, setErrors, setEnableServices, enableServices, pricingModel }) => {
    const { Title, Text } = Typography;
    const screens = useBreakpoint();
    const popup = usePopup();
    const current_venue = useSelector(
        (state) => state?.venues?.selectedVenue?.id,
    );

    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [statusFilter, setStatusFilter] = useState("all");
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const [localEnableServices, setLocalEnableServices] = useState(() => {
        const savedState = localStorage.getItem('enableServices');
        return savedState !== null ? JSON.parse(savedState) : true;
    });

       // Sync `enablePackages` state between parent and local storage
       useEffect(() => {
        setEnableServices(localEnableServices);
    }, [localEnableServices]);

    const handleEnablePackagesChange = (checked) => {
        setLocalEnableServices(checked);
        localStorage.setItem('enableServices', JSON.stringify(checked));
    };

    // Fetch services from API
    const fetchServices = async () => {
        setLoading(true);
        try {
            const res = await getServices({
                location: current_venue,
                is_active: statusFilter && statusFilter !== "all" ? statusFilter : "",
            });
            setServices(res?.data?.results);

            // Check for draftDetails or saved draft in localStorage
            if (draftDetails && draftDetails.length > 0) {
                const draftServiceIds = draftDetails.map(service => service.id);
                setSelectedServices(draftServiceIds);
            } else {
                const savedDraft = JSON.parse(localStorage.getItem('savedDraftServices'));
                if (savedDraft && savedDraft.length > 0) {
                    setSelectedServices(savedDraft);
                } else {
                    setSelectedServices(res?.data?.results.map(service => service.id)); // Select all if no draft
                }
            }

        } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
                window.location.href = "/server-error";
                console.log("Redirecting to server error page...");
            } else {
                popup.error("Oops...", error?.response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    };

    // Fetch services on mount or when pricingModel or current_venue changes
    useEffect(() => {
        if (current_venue) {
            fetchServices();
        }
    }, [pricingModel, current_venue]);

    // Handle checkbox change
    const handleServiceCheckboxChange = (service) => {
        const isSelected = selectedServices.includes(service.id);
        const updatedServices = isSelected
            ? selectedServices.filter(id => id !== service.id)
            : [...selectedServices, service.id];

        setSelectedServices(updatedServices);

        // Save selected services to localStorage
        localStorage.setItem('savedDraftServices', JSON.stringify(updatedServices));

        if (updatedServices.length === 0 || services.length === 0) {
            setErrors(prev => ({ ...prev, services: "Please select at least one service." }));
        } else {
            setErrors(prev => {
                const { services, ...rest } = prev;
                return rest;
            });
        }
    };

    // Handle description toggle
    const handleToggleDescription = (serviceId) => {
        setExpandedDescriptions((prev) => ({
            ...prev,
            [serviceId]: !prev[serviceId],
        }));
    };

    const dataSource = services.map((item) => ({
        key: item.id,
        name: item?.name,
        description: item?.description,
        service: item,
        image: item?.images?.length ? item?.images[0]?.image : templateImage,
        price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
    }));

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 40,
            render: (text) => (
                <img
                    src={text}
                    alt="Service"
                    style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px", opacity: enableServices ? '' : '0.4' }}
                />
            ),
        },
        {
            title: '',
            width: "50%",
            key: 'service',
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <h4 style={{ margin: 0, color: enableServices ? 'black' : '#999' }}>{record.name}</h4>
                    <p style={{ margin: 0, color: enableServices ? 'black' : '#999' }}>
                        {expandedDescriptions[record.key]
                            ? record.description
                            : `${record.description?.substring(0, 45)}${record.description?.length > 45 ? '...' : ''}`}
                        {record.description?.length > 45 && (
                            <span
                                style={{ color: "#1890ff", cursor: "pointer", marginLeft: "5px" }}
                                onClick={() => handleToggleDescription(record.key)}
                            >
                                {expandedDescriptions[record.key] ? 'Show Less' : 'Read More'}
                            </span>
                        )}
                    </p>
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <h4 style={{ margin: 0, color: enableServices ? 'black' : '#999' }}>${text || 0}</h4>,
        },
        {
            title: 'Select',
            key: 'select',
            width: 40,
            render: (text, record) => (
                <Checkbox
                    checked={selectedServices.includes(record.key)}
                    onChange={() => handleServiceCheckboxChange(record.service)}
                    disabled={!enableServices}
                />
            ),
        },
    ];

    return (
        <div>
            <Card
                style={{
                    marginTop: "20px",
                    backgroundColor: enableServices ? "white" : "#f0f0f0", // Disabled background
                    color: enableServices ? "black" : "#999", // Disabled text color
                }}
            >
                <Space style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Title level={4} style={{ color: enableServices ? "black" : "#999" }}>
                            Services
                        </Title>&nbsp;&nbsp;

                        {
                            screens.xs ?
                                error || !enableServices || selectedServices?.length === 0 ? (
                                    <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#FFCCCC" }}>
                                        <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                                    </div>
                                ) : (
                                    <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
                                        <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                    </div>
                                ) : null
                        }
                    </div>

                    <Switch
                        style={{ marginRight: "10px" }}
                        checked={localEnableServices}
                        onChange={handleEnablePackagesChange}
                    />
                </Space>

                <div className="events-details" style={{ marginTop: screens.xs ? "10px" : "0" }}>
                    <p
                        className="event-head"
                        style={{ color: enableServices ? "#667085" : "#999", pointerEvents: "none" }}
                    >
                        Select Services to add
                    </p>
                </div>

                <div className="packages-services-card" style={{ height: "140px" }}>
                    <Card>
                        {loading && !services?.length ? (
                            <Loader minHeight={100} />
                        ) : services?.length ? (
                            <Table
                                // scroll={{ x: 600 }}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                showHeader={false}
                                rowKey="key"
                                style={{ borderCollapse: "separate", borderSpacing: "0" }}
                            />
                        ) : !loading ? (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Packages" />
                        ) : null}
                    </Card>
                </div>
            </Card>
        </div>
    );
}

export default Services;