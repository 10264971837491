import { configureStore } from "@reduxjs/toolkit";
import LocationReducer from "./Location/LocationSlice";
import venuesReducer from "./VenuesSlice";
import companySlice from "./CompanySlice";
import roomReducer from './Rooms';

export default configureStore({
  reducer: {
    location: LocationReducer,
    venues: venuesReducer,
    room: roomReducer,
    company: companySlice,
  },
});
