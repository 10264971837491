import React, { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import { Card, Row, Col, Button, Typography, Spin, Tooltip, Space, Select, Modal, Input, DatePicker, InputNumber, message, Text } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, SaveOutlined, CheckOutlined, EyeTwoTone, PlusCircleOutlined, CheckSquareOutlined } from '@ant-design/icons';

import usePopup from '../../../../../common/MessageModal/usePopup';
import myApi from '../../../../Integrations/PaymentIntegration/UserData';

// UI's
import { PlusCircleFilled } from '@ant-design/icons';
import AddPayments from './AddPayments';
import { createPayment, getPaymentMethods, getPayments, refundPayment, updatePayment } from '../../../../../services/events';
import ConfirmationModal from "../../../../MainPage/Modals/ConfirmationModal/index";

import noPayment from '../../../../../assets/no-payments.svg';
import empty from '../../../../../assets/empty.svg';
import PaymentMode from './PaymentMode';
import './billings.scss';
import Receipt from './receipt';
import client from '../../../../../axios';
import AddCard from './AddCard';
import axios from 'axios';

const Billings = ({ totalPrice, eventId, event, setCurrent }) => {
    const navigate = useNavigate();
    const popup = usePopup();
    const { Title, Text } = Typography;
    const [addPayments, setAddPayments] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [payments, setPayments] = useState([]);
    const [paymentMode, setPaymentMode] = useState(false);
    const [eventPayment, setEventPayment] = useState(null);
    const [payButtonLoading, setPayButtonLoading] = useState(false);
    const [accountID, setAccountId] = useState(null);
    const [dateOfPayment, setDateOfPayment] = useState('');
    const [stripePromise, setStripePromise] = useState(null);
    const [stripeAccountStatus, setStripAccountStatus] = useState('')
    const [showReceipt, setShowReceipt] = useState(false);
    const [paymentID, setPaymentID] = useState(null);
    const [editingId, setEditingId] = useState(null);
    console.log(editingId)
    const [paymentExpected, setPaymentExpected] = useState(null);

    const [customDateVisible, setCustomDateVisible] = useState(false);
    const [selectedCustomDate, setSelectedCustomDate] = useState(null);
    const [openAddCard, setOpenAddCard] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([])

    const [showStripcard, setShowStripCard] = useState(false)

    const toggleEdit = (item) => {
        setEditingId(editingId === item.id ? null : item.id);
        setPaymentExpected(item.inst_payment_subtotal)
    };


    const getStripeAccountId = async () => {
        setLoading(true);
        try {
            const res = await myApi.get(`${process.env.REACT_APP_BASE_API_URL}/api/company/integration/stripe/`)
            setAccountId(res.data.data.account_id);
            setStripAccountStatus(res.data.data.status)
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { stripeAccount: res.data.data.account_id }));
        } catch (error) {
            setAccountId(null);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getStripeAccountId()

    }, [])

    const fetchAllPayments = async () => {
        setLoading(true);
        try {
            const res = await getPayments(eventId);
            setPayments(res?.data?.results);
        } catch (err) {
            popup.error('Opss!', err?.response?.data?.message, err);
        } finally {
            setLoading(false);
        }
    }

    const addNewpayment = async (no_of_installments) => {
        // if (!accountID) {
        //     message.error("Please Link Strip account to add payments")
        // }

        setLoading(true);
        try {
            await createPayment({
                event: eventId,
                status: "sched_installment",
                inst_payment_subtotal: totalPrice,
                no_of_installments: no_of_installments,
            });
            fetchAllPayments();
        } catch (err) {
            console.error('Error', err);
        } finally {
            setLoading(false);
        }

    }

    const paymentRefund = async (id, name) => {
        setPayButtonLoading(id);
        try {
            const res = await refundPayment(id);
            popup.success('Success!', `Your payment "${name}" has been refunded successfully!`);
            fetchAllPayments();
        } catch (err) {
            popup.error('Opss!', err?.response?.data?.message);
        } finally {
            setPayButtonLoading(false);
        }

    };

    // useEffect(() => {
    //     if (eventId && accountID && !loading) {
    //         fetchAllPayments();
    //     }
    // }, [eventId, accountID]);

    useEffect(() => {
        if (eventId && !loading) {
            fetchAllPayments();
        }
    }, [eventId]);


    // Paymnets dates
    const dateOfPaymentFunc = () => {
        setDateOfPayment(null);
        const currentDate = moment().startOf("day");
        const dueDate = moment
            .duration(
                moment(event?.event_date, "YYYY-MM-DD").diff(currentDate)
            )
            .asDays();

        if (dueDate >= 0) {
            let dates = [
                { value: event?.event_date, label: "Event day" },
            ];
            const loopCount = Math.ceil(dueDate / 5);
            for (let i = 1; i < dueDate && i < 5; i++) {
                dates.push({
                    value: moment(event?.event_date, "YYYY-MM-DD")
                        .subtract(i, "days")
                        .format("YYYY-MM-DD"),
                    label: i === 1 ? i + " day before" : i + " days before",
                });
            }
            for (let i = 1; i < loopCount + 1; i++) {
                dates.push({
                    value:
                        i * 5 >= dueDate
                            ? moment().format("YYYY-MM-DD")
                            : moment(event?.event_date, "YYYY-MM-DD")
                                .subtract(i * 5, "days")
                                .format("YYYY-MM-DD"),
                    label: i * 5 >= dueDate ? "Due today" : i * 5 + " days before",
                });
            }


            setDateOfPayment(dates);
        }
    };

    useEffect(() => {
        if (payments.length) {
            dateOfPaymentFunc();
        }
    }, [payments, event]);



    const getPaymentMethodsList = async () => {
        await getPaymentMethods(event.id).then((res) => {
            setPaymentMethods(res.data.data);
        }).catch((erorr) => {
            console.error("Failed to get payment methods list")
        })
    }

    const deleteCreditCard = (paymentMethodId, eventId) => {
        const accessToken = localStorage.getItem("access_token");
        const payload = {
            payment_method_id: paymentMethodId || paymentMethods[0]?.id,
            event_id: eventId || event.id,
        };
        axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/event/payment/method/delete/`, payload, {
            headers: {
                "Authorization": `JWT ${accessToken}`
            }
        })
            .then((resp) => {
                console.log("Card deleted successfully:", resp);
                message.success('Credit Card Deleted Successfully');
                fetchAllPayments();
                // getPaymentMethodsList();
                setOpenAddCard(false);
            })
            .catch((error) => {
                console.error("There was an error deleting the card:", error);
                message.error('Failed to delete credit card');
            });
    };

    // useEffect(() => {
    //     getPaymentMethodsList();
    // }, [])

    // update payment
    const updatePaymentonServer = async (id, date) => {
        let formData = new FormData();
        formData.append("scheduled_date", date);
        formData.append("paid_by_email", event?.contacts[0]?.email);
        setUpdateLoading(true);
        try {
            const res = await updatePayment(id, formData);
            fetchAllPayments();
        } catch (error) {
            popup.error('Opss!', error?.response?.data?.message);

        } finally {
            setUpdateLoading(false);
        }
    }

    const mapping = {
        unpaid: {
            buttonName: 'Scheduled',
            statusName: 'Unpaid',
        },
        failed: {
            buttonName: 'Pay',
            statusName: 'Failed',
        },
        refund: {
            buttonName: 'Pay',
            statusName: 'Refunded',
        },
        sched_installment: {
            buttonName: 'Pay',
            statusName: 'Scheduled',
        },
        overdue: {
            buttonName: 'Pay',
            statusName: 'Past Due',
        },
        paid: {
            buttonName: 'Refund',
            statusName: 'Paid',
        },
        partial_payment: {
            buttonName: 'Refund',
            statusName: 'Paid',
        },
        processing: {
            buttonName: 'Pay',
            statusName: 'Processing',
        }
    }

    // const renderDates = (index) => {
    //     if (index === 1 && payments.length > 2) {
    //         const today = moment().format('YYYY-MM-DD');
    //         const sameDay = Boolean(event?.event_date === today);
    //         const dates = dateOfPayment?.length ? dateOfPayment?.filter(item => sameDay ? sameDay : item.label !== 'Event day') : [];
    //         return dates
    //     } else if (index === 2 && payments[1]?.scheduled_date) {
    //         const dates = dateOfPayment?.length ? dateOfPayment?.filter(item => moment(item.value, 'YYYY-MM-DD').isAfter(payments[1]?.scheduled_date)) : [];
    //         return dates
    //     } else {
    //         return dateOfPayment
    //     }
    // }

    const renderDates = (index) => {
        try {
            if (index === 1 && payments.length > 2) {
                const today = moment().format('YYYY-MM-DD');
                const sameDay = Boolean(event?.event_date === today);
                const dates = dateOfPayment?.length ? dateOfPayment?.filter(item => sameDay ? sameDay : item.label !== 'Event day') : [];
                return [...dates, { label: "Custom Date", value: "custom" }];
            } else if (index === 2 && payments[1]?.scheduled_date) {
                const dates = dateOfPayment?.length ? dateOfPayment?.filter(item => moment(item.value, 'YYYY-MM-DD').isAfter(payments[1]?.scheduled_date)) : [];
                return [...dates, { label: "Custom Date", value: "custom" }];
            } else {
                return [...dateOfPayment, { label: "Custom Date", value: "custom" }];
            }
        } catch (error) {
            return []
        }
    };

    const eventDate = moment(event?.event_date);

    const disabledPastDate = (current, createdDate) => {
        return current.isBefore(dayjs(createdDate).startOf('day'), 'day');
    };


    if (loading) {
        return (
            <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                <Spin size={'large'} />
            </Card>
        )
    }
    const handleCustomDateSelect = (date, index) => {
        if (date) {
            setSelectedCustomDate({ date, index });
            setCustomDateVisible(false);
            updatePaymentonServer(payments[index].id, date.format('YYYY-MM-DD'));
        }
    };


    const handlePaymentUpdate = async (item) => {
        // convert paymentExpected to only 2 decimal
        const amount = (Math.round(paymentExpected * 100) / 100).toFixed(2)
        await client.put(`/api/event/payment/${item?.id}/`, { inst_payment_subtotal: amount }).then(async (res) => {
        }).catch((error) => {
            message.error(error?.response?.data?.message)
            setEditingId(!editingId);
        }).finally(async () => {
            await fetchAllPayments();
            setEditingId(!editingId);
        });
    }

    const handleAddPayment = async (id) => {
        await client.post(`api/event/payment/add-installment/`, {
            event_id: id
        }).then(async (res) => {
            await fetchAllPayments();
        }).catch((error) => {
            popup.error(error?.response?.data?.message)
        });
    }

    const handlePaymentDelete = async (id) => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Are you sure you want to delete this installment?',
            okText: 'OK',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    await client.delete(`/api/event/payment/${id}/`);
                    await fetchAllPayments();
                } catch (error) {
                    console.error(error);
                }
            },
            onCancel: () => {
                console.log('Deletion canceled');
            },
        });
    }
    const handleAddCreditCard = () => {
        if (!accountID || stripeAccountStatus !== "active") {
            setShowStripCard(true)
        }
        else {
            setOpenAddCard(true)
        }
    }


    return (
        <>

            <>
                {showReceipt && <Modal
                    title=""
                    open={showReceipt}
                    onOk={() => setShowReceipt(false)}
                    onCancel={() => setShowReceipt(false)}
                    width={1000}
                    footer={null}
                >
                    <Receipt payment_id={paymentID} />
                </Modal>}
                {
                    // payments.length <= 0 ||
                    !event?.chosen_package ? (
                        <>
                            <Tooltip title="Payment processor for your business.">
                                <Card  >
                                    <div style={{
                                        maxWidth: '343px',
                                        margin: 'auto',
                                        textAlign: 'center',
                                        padding: '24px',
                                    }}>
                                        <img
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                marginBottom: '24px',
                                            }}
                                            src={empty}
                                            alt="no package"
                                        />
                                        <h3
                                            style={{
                                                fontSize: '24px',
                                                fontWeight: '900',
                                            }}
                                        >
                                            Oops! It's Empty Here...
                                        </h3>
                                        <p
                                            style={{
                                                fontSize: '15px',
                                                color: '#667085',
                                            }}
                                        >
                                            You must have at least 1 package in the system. Start adding one of them and you can pay for the event.
                                        </p>
                                        <Button style={{ marginTop: "1rem" }} icon={<PlusCircleFilled />} type="primary" onClick={() => setCurrent("packages")}>
                                            Packages & services
                                        </Button>
                                    </div>


                                </Card>
                            </Tooltip>

                        </>

                        // <div style={{ padding: '16px' }}>
                        //     <Row gutter={16} className='biling-cards'>
                        //         <Col span={8}>
                        //             {/* card with edit and delete button in center */}
                        //             <Tooltip title="Add the credit or debit card details of your client">
                        //                 <Card
                        //                     className='biling-card-1'
                        //                 // style={{ borderColor: '#52c41a', cursor: 'pointer', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setOpenAddCard(true)}
                        //                 >
                        //                     <h3>Add Card Details</h3>

                        //                     {
                        //                         paymentMethods && paymentMethods.length > 0 ? (
                        //                             <>

                        //                                 <div style={{ padding: '10px' }} className='credit-card-checkbtn'>
                        //                                     <Button
                        //                                         style={{ backgroundColor: "#90EE90" }}

                        //                                         shape="default"
                        //                                         size="middle"
                        //                                         icon={<CheckOutlined size="large" style={{ fontSize: '24px', color: '#ffff', fontWeight: "bolder" }} />}
                        //                                         onClick={() => handleAddCreditCard(paymentMethods)}
                        //                                     />
                        //                                 </div>
                        //                                 <div style={{ padding: '10px' }} className="credit-card-action-btn">
                        //                                     <Button
                        //                                         type='primary'
                        //                                         shape="default"
                        //                                         size="middle"
                        //                                         icon={<EditOutlined style={{ fontSize: '24px', color: '#0499CB' }} />}
                        //                                         onClick={() => setOpenAddCard(true, paymentMethods)}
                        //                                         style={{ marginRight: '10px', backgroundColor: 'transparent', border: 'none' }} // Transparent background and no border
                        //                                     />

                        //                                     <Button
                        //                                         onClick={() => popup.confirm("Delete", "Are you sure? This action is permanent and cannot be undone.", deleteCreditCard)}
                        //                                         type='primary'
                        //                                         shape="default"
                        //                                         size="middle"
                        //                                         icon={<DeleteOutlined style={{ fontSize: '24px', color: "red" }} />}
                        //                                         style={{ backgroundColor: 'transparent', border: 'none' }}
                        //                                     />
                        //                                 </div>

                        //                             </>
                        //                         ) :
                        //                             (
                        //                                 <>
                        //                                     <div style={{ padding: '10px' }}>
                        //                                         <Button
                        //                                             onClick={handleAddCreditCard}
                        //                                             type="primary"
                        //                                             shape="circle"
                        //                                             size="large"
                        //                                             icon={<PlusOutlined size="large" style={{ fontSize: "16px", fontWeight: "bolder" }} />}
                        //                                         />
                        //                                     </div>
                        //                                 </>
                        //                             )
                        //                     }

                        //                     <p>{paymentMethods.length > 0 ? "Your credit/debit card details have been successfully added to your account." : "Add the credit or debit card details of your client."} </p>
                        //                 </Card>
                        //             </Tooltip>
                        //         </Col>
                        //         <Col span={8}>
                        //             <Tooltip title="Payment in full">
                        //                 <Card className='biling-card-1'
                        //                     // style={{ borderColor: '#52c41a', cursor: 'pointer', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        //                     onClick={() => addNewpayment(1)}
                        //                 >
                        //                     <div style={{ textAlign: 'center' }}>
                        //                         {/* <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> */}
                        //                         <h4>Select Payment option 1</h4>
                        //                         <div style={{ padding: '10px' }}>
                        //                             <Button
                        //                                 type="primary"
                        //                                 shape="circle"
                        //                                 size="large"
                        //                                 icon={<PlusOutlined size="large" style={{ fontSize: "16px", fontWeight: "bolder" }} />}
                        //                             />
                        //                         </div>
                        //                         <p className='biling-card-1-paragraph'>Payment in full</p>
                        //                     </div>
                        //                 </Card>
                        //             </Tooltip>
                        //         </Col>
                        //         <Col span={8}>
                        //             <Tooltip title="Payment split in 2">
                        //                 <Card
                        //                     className='biling-card-1'
                        //                     // style={{ borderColor: '#52c41a', cursor: 'pointer', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        //                     onClick={() => addNewpayment(2)}
                        //                 >
                        //                     <div style={{ textAlign: 'center' }}>
                        //                         {/* <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> */}
                        //                         <h4>Select Payment option 2</h4>
                        //                         <div style={{ padding: '10px' }}>
                        //                             <Button
                        //                                 type="primary"
                        //                                 shape="circle"
                        //                                 size="large"
                        //                                 icon={<PlusOutlined size="large" style={{ fontSize: "16px", fontWeight: "bolder" }} />}
                        //                             />
                        //                         </div>
                        //                         <p className='biling-card-1-paragraph'>payment split in 2</p>
                        //                     </div>
                        //                 </Card>
                        //             </Tooltip>
                        //         </Col>
                        //     </Row>
                        //     <Row gutter={16} className='biling-cards'>
                        //         <Col span={8}>
                        //             <Tooltip title="Payment split in 3">
                        //                 <Card
                        //                     className='biling-card-1'
                        //                     // style={{ borderColor: '#52c41a', cursor: 'pointer', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        //                     onClick={() => addNewpayment(3)}
                        //                 >
                        //                     <div style={{ textAlign: 'center' }}>
                        //                         {/* <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> */}
                        //                         <h4>Select Payment option 3</h4>
                        //                         <div style={{ padding: '10px' }}>
                        //                             <Button
                        //                                 type="primary"
                        //                                 shape="circle"
                        //                                 size="large"
                        //                                 icon={<PlusOutlined size="large" style={{ fontSize: "16px", fontWeight: "bolder" }} />}
                        //                             />
                        //                         </div>
                        //                         <div className='biling-card-1-paragraph'>Payment split in 3</div>
                        //                     </div>
                        //                 </Card>
                        //             </Tooltip>
                        //         </Col>
                        //         <Col span={8}>
                        //             <Tooltip title="Customise payment numbers">
                        //                 <Card
                        //                     className='biling-card-1'
                        //                     // style={{ borderColor: '#52c41a', cursor: 'pointer', height: '140px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        //                     // onClick={() => addNewpayment(5)}
                        //                     onClick={() => setAddPayments(true)}
                        //                 >
                        //                     <div style={{ textAlign: 'center' }}>
                        //                         {/* <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} /> */}
                        //                         <h4>Select Custom Payment</h4>
                        //                         <div style={{ padding: '10px' }}>
                        //                             <Button
                        //                                 type="primary"
                        //                                 shape="circle"
                        //                                 size="large"
                        //                                 icon={<PlusOutlined size="large" style={{ fontSize: "16px", fontWeight: "bolder" }} />}
                        //                             />
                        //                         </div>
                        //                         <p className='biling-card-1-paragraph'>Customize payment numbers</p>
                        //                     </div>
                        //                 </Card>
                        //             </Tooltip>
                        //         </Col>
                        //         {(!accountID || stripeAccountStatus !== "active") && <Col span={8}>
                        //             <Tooltip title="Payment processor for your business.">
                        //                 <Card
                        //                     className='biling-card-1'

                        //                     // style={{ borderColor: '#52c41a', cursor: 'pointer', height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        //                     onClick={() => navigate('/Integration/LinkStripe')}
                        //                 >
                        //                     <div style={{ textAlign: 'center' }}>
                        //                         {/* <img  src={!accountID ? noPayment : empty} alt="no payments" /> */}
                        //                         {/* <h5>No linked Bank account is found" : "Oops! Its Empty Here...</h5> */}
                        //                         <h4>Link Stripe Account</h4>
                        //                         {/* <p>To receive funds from your event, add a bank account.' : 'Please click on below button to create payments for your event.</p> */}
                        //                         {/* <Button type="primary" >Create Stripe Account</Button> */}
                        //                         <div style={{ padding: '10px' }}>
                        //                             <Button
                        //                                 type="primary"
                        //                                 shape="circle"
                        //                                 size="large"
                        //                                 icon={<PlusOutlined size="large" style={{ fontSize: "16px", fontWeight: "bolder" }} />}
                        //                             />
                        //                         </div>
                        //                         {/* <CheckSquareOutlined style={{ fontSize: '24px', color: '#90EE90' }} /> */}
                        //                         {/* <h3>Link Stripe Account</h3> */}
                        //                         <p className='biling-card-1-paragraph'>Payment processor for your business.</p>
                        //                     </div>
                        //                 </Card>
                        //             </Tooltip>
                        //         </Col>}
                        //     </Row>
                        // </div >
                    ) : (
                        <>
                            {
                                payments.length <= 0 ? (
                                    <>
                                        <Card  >
                                            <div style={{
                                                maxWidth: '343px',
                                                margin: 'auto',
                                                textAlign: 'center',
                                                padding: '24px',
                                            }}>
                                                <img
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        marginBottom: '24px',
                                                    }}
                                                    src={noPayment}
                                                    alt="no package"
                                                />
                                                <Text
                                                    style={{
                                                        fontSize: '20px',
                                                        fontWeight: '500',
                                                    }}
                                                >
                                                    No Installments availible!
                                                </Text>
                                                {/* <p
                                                            style={{
                                                                fontSize: '15px',
                                                                color: '#667085',
                                                            }}
                                                        >
                                                            No Installments availible
                                                        </p> */}
                                            </div>
                                        </Card>
                                    </>
                                ) : (
                                    <>
                                        <Row style={{ marginTop: '24px', justifyContent: 'center' }}>
                                            <Space className='navigationBar' style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Title level={4}>Current Payments</Title>
                                            </Space>
                                            {payments.filter(item => item.inst_payment_remitted_w_tax >= 0).map((item, i) => (
                                                <Card
                                                    key={i}
                                                    className='initial-payment-card'
                                                    // title={item.title}
                                                    title="Deposit"
                                                    extra={
                                                        <Space>
                                                            <Text style={{ fontWeight: 600, marginLeft: '16px' }}>Day of payment:</Text>
                                                            {
                                                                i === 0 ? (
                                                                    <>
                                                                        <DatePicker
                                                                            status={item.status === "overdue" ? "error" : ""}
                                                                            disabled={item.status === "paid" || item.status === "processing" || item.status === "partial_payment"}
                                                                            onChange={(date) => handleCustomDateSelect(date, i)}
                                                                            defaultValue={dayjs(item.scheduled_date.split(' ')[0], 'YYYY-MM-DD')}
                                                                            format="DD-MM-YYYY" disabledDate={(current) => disabledPastDate(current)}
                                                                            dateRender={(current) => {
                                                                                const style = {};
                                                                                if (current.isSame(eventDate, 'day')) {
                                                                                    style.backgroundColor = '#F5F5F5';
                                                                                    style.color = 'red';
                                                                                }
                                                                                return (
                                                                                    <div className="ant-picker-cell-inner" style={style}>
                                                                                        {current.date()}
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        />
                                                                    </>
                                                                )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <Select
                                                                                status={item.status === "overdue" ? "error" : ""}
                                                                                style={{ minWidth: 120, color: "red" }}
                                                                                placeholder="Select date"
                                                                                // value={
                                                                                //     i === 0 ? { label: `${moment(item.scheduled_date).format("YYYY-MM-DD")}`, value: 0 }
                                                                                //         : { label: (item.scheduled_date ? moment(item.scheduled_date).format("YYYY-MM-DD") : "Select date"), value: 0 }
                                                                                // }
                                                                                value={
                                                                                    { label: (item.scheduled_date ? moment(item.scheduled_date).format("DD-MM-YYYY") : "Select date"), value: 0 }
                                                                                }
                                                                                options={renderDates(i)}
                                                                                loading={updateLoading}
                                                                                onChange={(value) => {
                                                                                    if (value === "custom") {
                                                                                        setCustomDateVisible(true);
                                                                                        setSelectedCustomDate({ date: null, index: i });
                                                                                    } else {
                                                                                        updatePaymentonServer(item.id, value);
                                                                                    }
                                                                                }}
                                                                                // created date is disabled
                                                                                // disabled={item.status === "partial_payment" || item.status === "paid" || i === 0}
                                                                                disabled={item.status === "partial_payment" || item.status === "paid" || item.status === "processing"}
                                                                            />
                                                                        </>
                                                                    )
                                                            }

                                                            {customDateVisible && selectedCustomDate?.index === i && (
                                                                <DatePicker
                                                                    onChange={(date) => handleCustomDateSelect(date, i)}
                                                                    status={item.status === "overdue" ? "error" : ""}
                                                                    defaultValue={item.scheduled_date ? (dayjs(item.scheduled_date.split(' ')[0], 'YYYY-MM-DD')) : ""}
                                                                    format="DD-MM-YYYY"
                                                                    disabledDate={(current) => disabledPastDate(current)}
                                                                    dateRender={(current) => {
                                                                        const style = {};
                                                                        if (current.isSame(eventDate, 'day')) {
                                                                            style.backgroundColor = '#F5F5F5';
                                                                            style.color = 'red';
                                                                        }
                                                                        return (
                                                                            <div className="ant-picker-cell-inner" style={style}>
                                                                                {current.date()}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                />

                                                            )}

                                                            {/* <InputNumber
                                                    style={{ width: 120 }}
                                                     placeholder="Amount"
                                                       min={0}
                                                     value={`${item.inst_payment_subtotal}$`}
                                                     onChange={(value) => handleAmountChange(item.id, value)}
                                                      /> */}
                                                            {item.status === "partial_payment" || item.status === "paid" ? (
                                                                <div>
                                                                    <Button
                                                                        type="primary"
                                                                        className='initial-payment-button refund'
                                                                        size='medium'
                                                                        loading={payButtonLoading === item.id}
                                                                        onClick={() => {
                                                                            if (!accountID) {
                                                                                setShowStripCard(true);
                                                                            } else {
                                                                                paymentRefund(item.id, item.title);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {/* github fixes */}
                                                                        {mapping[item.status]?.buttonName}
                                                                    </Button>
                                                                    <Tooltip title="View Receipt">
                                                                        <EyeTwoTone
                                                                            style={{ padding: '10px' }}
                                                                            onClick={() => {
                                                                                setShowReceipt(true);
                                                                                setPaymentID(item.id);
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            ) : (
                                                                item.status !== "not_paid" ? (
                                                                    <Button
                                                                        type="primary"
                                                                        className='initial-payment-button'
                                                                        size='medium'
                                                                        loading={payButtonLoading === item.id}
                                                                        disabled={!item.scheduled_date || item.status === "processing" || item.inst_payment_remitted_w_tax <= 0}
                                                                        onClick={() => {
                                                                            // if (!accountID || !stripeAccountStatus === "active") {
                                                                            //     setShowStripCard(true);
                                                                            // } else {
                                                                            setPaymentMode(true);
                                                                            setEventPayment(item);
                                                                            // }
                                                                        }}
                                                                    >
                                                                        {mapping[item.status]?.buttonName}
                                                                    </Button>
                                                                ) : (
                                                                    null
                                                                )
                                                            )}
                                                            {i > 0 &&
                                                                <Button
                                                                    // style={{ marginTop: "10px" }}
                                                                    size='medium'
                                                                    icon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                                                                    disabled={item.status === "paid" || item.status === "processing" || item.status === "partial_payment"}
                                                                    onClick={() => handlePaymentDelete(item.id)}
                                                                />
                                                            }
                                                        </Space>
                                                    }
                                                >
                                                    <div className='payment-info'>
                                                        <div>
                                                            {/* InputNumber */}
                                                            {/* <Input
                                                                        prefix="$"
                                                                        defaultValue={item.inst_payment_subtotal}
                                                                        disabled={item.status === "paid" || item.status === "processing" || item.status === "partial_payment"}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter") {
                                                                                handlePaymentUpdate(item.id);
                                                                            }
                                                                        }}
                                                                        onChange={(e) => setPaymentExpected(e.target.value)}
                                                                    /> */}
                                                            <Input
                                                                prefix="$"
                                                                suffix={editingId != item.id ? <EditOutlined style={{ cursor: 'pointer', textAlign: "right" }} onClick={() => toggleEdit(item)} /> : ""}
                                                                defaultValue={item.inst_payment_remitted_w_tax}
                                                                disabled={editingId != item.id || item.status === "paid" || item.status === "processing" || item.status === "partial_payment"}
                                                                onChange={(e) => setPaymentExpected(e.target.value)}
                                                                style={{ width: 150, marginRight: 10 }}
                                                            />
                                                            {editingId === item.id ? (
                                                                <Button type="primary" onClick={() => handlePaymentUpdate(item)}>
                                                                    Save
                                                                </Button>
                                                            ) : null}

                                                        </div>
                                                        {/* <div className={`payment-status ${item.status === 'partial_payment' ? 'paid' : ''}`}> */}
                                                        <div className={`payment-status ${item.status === 'paid' ? 'paid' : ''}`}>
                                                            <span >
                                                                {mapping[item.status]?.statusName}
                                                                {/* {item.status === "partial_payment" ? 'Paid' :
                                                        item.status === "refund" ? 'Refunded' :
                                                            item.status === "sched_installment" ? 'Scheduled' :
                                                                item.status.replaceAll('_', ' ')} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))}

                                            {payments.length < 5 && payments.length > 0 && (
                                                <div style={{ width: '100%', alignItems: "start", marginTop: "1rem" }} >
                                                    <Button style={{ color: "#0499CB" }} type="dashed" icon={<PlusOutlined />} onClick={() => handleAddPayment(event.id)}>
                                                        Add New Installments
                                                    </Button>
                                                </div>
                                            )}
                                        </Row>
                                    </>
                                )
                            }

                        </>
                    )}

                {
                    openAddCard &&
                    <Elements stripe={stripePromise}>
                        <AddCard
                            open={openAddCard}
                            handelClose={() => setOpenAddCard(false)}
                            stripePromise={stripePromise}
                            fetchAllPayments={fetchAllPayments}
                            // getPaymentMethodsList={getPaymentMethodsList}
                            deleteCreditCard={deleteCreditCard}
                            event={event}
                            showStripcard={showStripcard}
                        />
                    </Elements>
                }
                <Elements stripe={stripePromise}>
                    <PaymentMode
                        open={paymentMode}
                        handelClose={() => setPaymentMode(false)}
                        payment={eventPayment}
                        setPayButtonLoading={setPayButtonLoading}
                        payButtonLoading={payButtonLoading}
                        fetchAllPayments={fetchAllPayments}
                        event={event}
                        stripePromise={stripePromise}
                        cards={paymentMethods}
                        deleteCreditCard={deleteCreditCard}
                        accountID={accountID}
                    // getPaymentMethodsList={getPaymentMethodsList}
                    />
                </Elements>
                <AddPayments
                    open={addPayments}
                    totalPrice={totalPrice}
                    handelClose={setAddPayments}
                    fetchAllPayments={fetchAllPayments}
                    eventId={eventId}
                />
            </>
        </>

    );
};

export default Billings;
