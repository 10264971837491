import React, { useState, useEffect } from "react";
// UI's
import { Typography, Card, Button, Input, Row, Col, Switch, Select, DatePicker, Grid } from "antd";
import { PlusOutlined, DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { useBreakpoint } = Grid;

const Payments = ({ draftDetails, createdPayments }) => {
  const { Title } = Typography;
  const screens = useBreakpoint();
  const [installment, setInstallment] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (draftDetails?.payment_terms?.length > 0) {
      const populatedPayments = draftDetails.payment_terms.map((term, index) => ({
        title: `Payment ${index + 1}`, // Title can be generated dynamically
        type: term.is_partials_allowed ? "credit-card" : "debit-card", // Adjust based on your logic
        payment_date: term.payment_date || "", // Ensure it defaults to an empty string if not provided
        percentage: term.percentage || "0.00", // Optional: Populate percentage if needed
      }));
  
      setPayments(populatedPayments);
    } else {
      // Default Payment if no draft details are available
      const defaultPayment = [
        {
          title: "Payment 1",
          type: "credit-card",
          payment_date: new Date().toISOString().split('T')[0],
        },
      ];
      setPayments(defaultPayment);
    }
  }, [draftDetails]);
  

  useEffect(() => {
    if (payments.length) {
      const updated = payments.map((item) => {
        const { title, type, ...rest } = item;
        return {
          ...rest,
          percentage: payments.length ? (100 / payments.length).toFixed(2) : 100,
          is_partials_allowed: installment,
        };
      });
      createdPayments(updated);
    }
  }, [payments, installment]);

  const addNewPayment = () => {
    const lastPayment = payments[payments.length - 1];
    const newPayment = {
      title: `Payment ${payments.length + 1}`,
      type: lastPayment.type,
      payment_date: lastPayment.payment_date === 0 ? 1 : lastPayment.payment_date,
    };
    setPayments((prev) => [...prev, newPayment]);
  };

  const updatePaymentAtIndex = (index, key, value) => {
    const newData = [...payments];
    newData[index] = { ...newData[index], [key]: value };
    setPayments(newData);
  };

  const deletePaymentAtIndex = (index) => {
    const newData = [...payments];
    newData.splice(index, 1);
    setPayments(newData);
  };

  // Disable past dates in the DatePicker
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <Card style={{ marginTop: screens.xs ? "120px" : "20px" }}>
      <div className="payment-header">
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Title level={4}>Payment Terms</Title>&nbsp;
          {screens.xs ? (
            <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
              <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
            </div>
          ) : null}
        </div>
        {/* <p>
          <Switch
            checked={installment}
            disabled
            onChange={(val) => {
              if (!val) {
                setPayments([{ title: "Payment 1", payment_date: "" }]);
              }
              setInstallment(val);
            }}
          />{" "}
          Enable Installment Payments
        </p> */}
      </div>

      <Row style={{ marginTop: "10px" }} gutter={[16, 16]}>
        {payments.map((pay, i) => (
          <React.Fragment key={i}>
            <Col span={screens.xs ? 24 : 7}>
              <p className="payment-type" style={{ color: "#777980" }}>Payment Description</p>
              <Input
                size="large"
                style={{ backgroundColor: "#f9f9fc", width: "100%" }}
                value={pay?.title}
                onChange={(e) => updatePaymentAtIndex(i, "title", e.target.value)}
              />
            </Col>
            <Col span={screens.xs ? 24 : 7}>
              <p className="payment-type" style={{ color: "#777980" }}>Payment Method</p>
              <Select
                size="large"
                style={{ width: "100%", backgroundColor: "#f9f9fc" }}
                value={pay.type}
                onChange={(val) => updatePaymentAtIndex(i, "type", val)}
                options={[
                  { value: "debit-card", label: "Debit Card" },
                  { value: "credit-card", label: "Credit Card" },
                ]}
              />
            </Col>
            <Col span={screens.xs ? 24 : 7}>
              <p className="payment-type" style={{ color: "#777980" }}>Date of Payment</p>
              {i === 0 ? (
                <Input
                  size="large"
                  style={{ width: "100%", backgroundColor: "#f9f9fc" }}
                  value={pay.payment_date}
                  options={[{ value: 0, label: "Booking Day" }]}
                  onChange={(val) => updatePaymentAtIndex(0, "payment_date", "")}
                  disabled={i === 0}
                />
              ) : (
                <DatePicker
                disabledDate={disabledDate}
                size="large"
                value={pay.payment_date ? dayjs(pay.payment_date) : null} // Convert string date to dayjs object
                onChange={(date) =>
                  updatePaymentAtIndex(i, "payment_date", dayjs(date).format('YYYY-MM-DD'))
                }
                style={{ backgroundColor: "#f9f9fc", width: '100%' }}
              />
              )}
            </Col>
            <Col span={2}>
              {i ? (
                <Button
                  className="delete-button"
                  onClick={() => deletePaymentAtIndex(i)}
                >
                  <DeleteOutlined style={{ color: "#EB3D4D" }} />
                </Button>
              ) : null}
            </Col>
          </React.Fragment>
        ))}
      </Row>

      {installment && payments.length < 3 ? (
        <Button className="button-terms" onClick={addNewPayment} size="large">
          <PlusOutlined /> Add Payments
        </Button>
      ) : null}
    </Card>
  );
};

export default Payments;