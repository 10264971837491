import React, { useState, useEffect } from "react";
import Content from "./components/Content";
import "./MainPage.scss";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
  const navigate = useNavigate();
  const [tabPosition, setTabPosition] = useState("Venues");

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login");
      localStorage.removeItem("selectedVanue");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Content tabPosition={tabPosition} setTabPosition={setTabPosition} />;
};

export default MainPage;
