import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Input, Select, Radio, Tooltip } from 'antd';
import Loader from '../../../common/Loader';
import { InfoOutlined } from "@ant-design/icons";
import '../EndUserStyles.scss';
import dummyPhoto from '../Assets/photo.png';
import { checkGuestCount } from "../../../services/integrations";

const { useBreakpoint } = Grid;

const Rooms = ({ room, setGuestCountViaId, setRoom, rooms, pricingModel }) => {
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const screens = useBreakpoint();
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);


  const checkGuestCounts = async (room) => {
    try {
      setSubmitLoading(true); // Ensure loading starts before making the request
      const response = await checkGuestCount(room[0]?.id);
      console.log(response.data.max_guests);
      setGuestCountViaId(response.data.max_guests);
      localStorage.setItem("guestCount", response.data.max_guests);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "An unexpected error occurred.";
      console.log(JSON.stringify(errorMessage));
    } finally {
      setSubmitLoading(false); // Ensure loading stops after the request completes
    }
  };

  const handleRoomSelection = (record, isChecked) => {
    console.log(record, isChecked);
    setRoom((prevRooms) => {
      if (isChecked) {
        return [...prevRooms, record];
      } else {
        return prevRooms.filter((room) => room.id !== record.id);
      }
    });
  };

  // Mapping the rooms into data source format for the table
  const dataSource = rooms?.map((item, index) => ({
    id: item?.id,
    key: index,
    name: item?.name,
    max_guests: item.max_guests,
    description: item?.description || "",
    image: item?.images?.length ? item?.images[0]?.image : dummyPhoto,
    price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
  }));
  const handleToggleDescription = (roomId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [roomId]: !prev[roomId],
    }));
  };
  // Columns for Ant Design Table
  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 40,
      render: (text) => (
        <img
          src={text}
          alt="Room"
          style={{ width: '50px', height: '50px', borderRadius: '5px', marginTop: '3px' }}
        />
      ),
    },
    {
      title: '',
      width: "50%",
      key: 'room',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
          <h4 style={{ margin: 0 }}>{record.name}</h4>
          <p style={{ margin: 0 }}>
            {expandedDescriptions[record.key]
              ? record.description
              : `${record.description?.substring(0, 45)}${record.description?.length > 45 ? '...' : ''}`}
            {record.description?.length > 45 && (
              <span
                style={{ color: "#1890ff", cursor: "pointer", marginLeft: "5px" }}
                onClick={() => handleToggleDescription(record.key)}
              >
                {expandedDescriptions[record.key] ? 'Show Less' : 'Read More'}
              </span>
            )}
          </p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <h4 style={{ margin: 0 }}>${record.price || 0}</h4>,
    },
    {
      title: 'Select',
      key: 'select',
      width: 40,
      render: (text, record) => (
        <Checkbox
          checked={room.some((selectedRoom) => selectedRoom.id === record.id)}
          onChange={(e) => {
            handleRoomSelection(record, e.target.checked);
            // if (e.target.checked) {
            //   setRoom([record]);
            // } else {
            //   setRoom([]);
            // }
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Card style={{ marginTop: screens.xs ? "10px" : "20px" }}>
        <Space
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>Rooms</Title>
          <Tooltip
            placement="top"
            title={`Pricing will be calculated as ${pricingModel === "PER_PERSON" ? "Per Person" : "Flat Price"}`}
          >
            <InfoOutlined
              className="add-input-field"
              style={{ marginBottom: '10px', cursor: 'pointer', marginLeft: '2px' }}
            />
          </Tooltip>
        </Space>
        <div
          className="events-details"
          style={{ marginTop: screens.xs ? "10px" : "0" }}
        >
          <p
            className="event-head"
            style={{ color: "#667085", pointerEvents: "none" }}
          >
            Select Rooms to add
          </p>
        </div>

        <div className="item-details" style={{ height: "140px" }}>
          <Card>
            {loading && !rooms?.length ? (
              <Loader minHeight={400} />
            ) : rooms?.length ? (
              <Table
                // scroll={{ x: 600 }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                showHeader={false}
              />
            ) : !loading ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Rooms"
              />
            ) : null}
          </Card>
        </div>

      </Card>

      {/* </div> */}
    </>

  );
};

export default Rooms;