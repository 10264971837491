import { Modal, DatePicker, TimePicker, Upload, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "./AddNewPackage.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Select, Row, Col } from "antd";
import MessageModal from "../MessageModal";
import axios from "axios";
import ImageUploader from "../../../../components/ImageUploader";
import AddImage from "../AddImage";
import { useSelector } from "react-redux";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const { RangePicker } = DatePicker;

const AddNewPackage = ({ open, setOpen, getPackages, selectedRoom }) => {
  const location = useSelector((state) => state.venues.selectedVenue);
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [file, setFile] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [services, setServices] = useState([]);
  const [room, setRoom] = useState(null);
  const [selectedService, setSelectedService] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [packageName, setPackageName] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [isSpin, setIsSpin] = useState(false);
  const [packagePrice, setPackagePrice] = useState("");
  const [packageDuration, setPackageDuration] = useState();
  const [packageStartDate, setPackageStartDate] = useState("");
  const [packageEndDate, setPackageEndDate] = useState("");
  const [totalSelectedServicePrice, setTotalSelectedServicePrice] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isRoomError, setIsRoomError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState([]);

  const [isAddImage, setIsAddImage] = useState(false);
  const onChange = (date, dateString) => {
    setPackageStartDate(dateString[0]);
    setPackageEndDate(dateString[1]);
    setSelectedDateRange(date);
  };

  const getRooms = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/room/?location=${location?.id}&page_size=50`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      )
      .then((res) => {
        setRooms(res.data.results);
      });
  };

  const getServices = () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/?location=${location?.id}`,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          },
        )
        .then((res) => {
          setServices(res.data.results);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getRooms();
    getServices();
  }, []);

  useEffect(() => {
    if (selectedRoom) {
      const selected = { label: selectedRoom?.name, value: selectedRoom?.id };
      setRoom(selected);
      setIsRoomError(false);
      const filter = rooms.find((item) => item.id === selectedRoom.id);
      setCurrentRoom(filter);
    }
  }, [selectedRoom, rooms]);

  const submitData = (status) => {
    setIsSpin(true);

    const formData = new FormData();
    if (status !== "draft") {
      if (packageName) {
        formData.append("name", packageName);
      }
      if (packageDescription) {
        formData.append("description", packageDescription);
      }
      if (packagePrice) {
        formData.append("price", packagePrice);
      }
      if (packageStartDate) {
        formData.append("start_date", packageStartDate);
      }
      if (packageEndDate) {
        formData.append("end_date", packageEndDate);
      }
      if (location?.id) {
        formData.append("location", location?.id);
      }
      if (room) {
        formData.append("room", room?.value ? room?.value : room);
      }
      if (selectedService) {
        formData.append("addons", JSON.stringify(selectedService));
      }
      if (file) {
        formData.append("image", file);
      }
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i].originFileObj);
        }
      }
      if (packageDuration) {
        formData.append("duration_minutes", packageDuration);
      }
      if (status) {
        formData.append("status", status);
      }
    } else {
      if (packageName) {
        formData.append("name", packageName);
      }
      if (packageDescription) {
        formData.append("description", packageDescription);
      }
      if (packagePrice) {
        formData.append("price", packagePrice);
      }
      if (packageStartDate) {
        formData.append("start_date", packageStartDate);
      }
      if (packageEndDate) {
        formData.append("end_date", packageEndDate);
      }
      if (location?.id) {
        formData.append("location", location?.id);
      }
      if (room) {
        formData.append("room", room);
      }
      if (selectedService) {
        formData.append("addons", JSON.stringify(selectedService));
      }
      if (file) {
        formData.append("image", file);
      }
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i].originFileObj);
        }
      }
      if (packageDuration) {
        formData.append("duration_minutes", packageDuration);
      }
      if (status) {
        formData.append("status", status);
      }
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/package/`,
        formData,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      )
      .then((res) => {
        setFile(null);
        setPackageEndDate("");
        setPackageStartDate("");
        setPackagePrice("");
        setPackageDescription("");
        setPackageName("");
        setPackageDuration("");
        setRoom(null);
        setCurrentRoom(null);
        setSelectedDateRange(null);
        setIsSpin(false);
        setOpen(false);
        getPackages();
        setImages([]);
      })
      .catch((eror) => {
        setErrorMessage(eror.response.data.message.location);
        setIsSpin(false);
        setIsErrorModal(true);
      });
  };

  useEffect(() => {
    const getSelected = services.filter((item) =>
      selectedService?.includes(item.id),
    );
    if (getSelected) {
      const serviceValue = getSelected
        ?.map((item) => item?.price)
        ?.reduce((a, b) => a + b, 0);
      setTotalSelectedServicePrice(serviceValue);
    }
  }, [selectedService]);

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="add-new-package"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen) {
                  setOpen(false);
                  setIsNameError(false);
                  setIsDescriptionError(false);
                  setIsPriceError(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Add New Package</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setOpen(false);
                setFile(null);
                setPackageEndDate("");
                setPackageStartDate("");
                setPackagePrice("");
                setPackageDescription("");
                setPackageName("");
                setIsNameError(false);
                setIsDescriptionError(false);
                setIsPriceError(false);
                setPackageDuration("");
                setRoom(null);
                setCurrentRoom(null);
                setSelectedDateRange(null);
                setImages([]);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <div className="content-edit-company-2">
            <div className="content-edit-company-input-wrapper">
              <Input
                placeholder="Package Name*"
                value={packageName}
                onChange={(e) => {
                  setPackageName(e.target.value);
                }}
                size="large"
                required
              />

              {isNameError ? (
                <span style={{ color: "red" }}>Package Name is required</span>
              ) : null}
            </div>
            <div className="content-edit-company-input-wrapper">
              <Input
                placeholder="Description*"
                value={packageDescription}
                onChange={(e) => {
                  setPackageDescription(e.target.value);
                }}
                size="large"
                required
              />

              {isDescriptionError ? (
                <span style={{ color: "red" }}>
                  Package Description is required
                </span>
              ) : null}
            </div>

            <Row gutter={[16, 16]}>
              <Col md={12}>
                <div className="content-edit-company-input-wrapper">
                  <Input
                    placeholder="Price*                                    $0.00"
                    size="large"
                    value={packagePrice}
                    type="number"
                    onChange={(e) => {
                      setPackagePrice(e.target.value);
                    }}
                  />
                  {isPriceError ? (
                    <span style={{ color: "red" }}>
                      Package Price is required
                    </span>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <div className="content-edit-company-input-wrapper">
                  <Input
                    placeholder="Package Duration                     --"
                    size="large"
                    value={packageDuration}
                    onChange={(e) => {
                      setPackageDuration(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={24}>
                <div className="content-edit-company-input-wrapper">
                  <RangePicker onChange={onChange} value={selectedDateRange} />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              {images.length
                ? images.map((file) => (
                    <Col sm={12}>
                      <div className="uploaded-image-wrapper">
                        <img
                          key={file.uid}
                          src={
                            file.thumbUrl ||
                            URL.createObjectURL(file.originFileObj)
                          }
                          alt={file.name}
                        />
                      </div>
                    </Col>
                  ))
                : null}
              <Col sm={12}>
                <div
                  className="add-new-img"
                  onClick={() => setImageModal(true)}
                >
                  <span>Add Image</span>
                  <button>Add</button>
                </div>
                {imageModal ? (
                  <ImageUploader
                    open={imageModal}
                    setOpen={setImageModal}
                    images={images}
                    setImages={(images) => {
                      setImages(images);
                    }}
                  />
                ) : null}
              </Col>
            </Row>
            {/* 
            <Row gutter={[16, 16]}>
              <Col sm={12}>
                <div className="add-new-img">
                  <span>Add Image</span>
                  <button
                    onClick={() => {
                      setIsAddImage(true);
                      setOpen(false);
                    }}
                  >
                    Add
                  </button>
                </div>
              </Col>
              <Col md={12}></Col>
            </Row> */}
          </div>
        )}
        {isSecondScreen && (
          <>
            <Spin spinning={isSpin} delay={400}>
              <div className="content-second-screen">
                <Row gutter={[16, 16]}>
                  <Col md={24}>
                    <div className="content-edit-company-input-wrapper">
                      <Select
                        placeholder="Select Room to associate with"
                        style={{
                          width: "100%",
                        }}
                        disabled={selectedRoom?.id}
                        onChange={(value) => {
                          setRoom(value);
                        }}
                        value={room}
                        size="large" // onChange={handleChange}
                        options={rooms.map((room) => {
                          return {
                            value: room.id,
                            label: room.name,
                          };
                        })}
                      />
                      {isRoomError ? (
                        <span style={{ color: "red" }}>Room is required</span>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={24}>
                    <div className="content-edit-company-input-wrapper">
                      <Select
                        placeholder="Select Services to associate with"
                        style={{
                          width: "100%",
                        }}
                        allowClear
                        mode="multiple"
                        value={selectedService}
                        onChange={(value) => {
                          setSelectedService(value);
                        }}
                        size="large" // onChange={handleChange}
                        options={services.map((service) => {
                          return {
                            value: service?.id,
                            label: service.name,
                          };
                        })}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="max-guests">
                  <span>Max guest</span>
                  <span className="value-box">
                    {currentRoom?.max_guests ? currentRoom?.max_guests : 0}
                  </span>
                </div>

                <div className="sub-total-wrapper">
                  <div className="sub-total">
                    <span>Subtotal</span>
                    <span>
                      $
                      {packagePrice || currentRoom || totalSelectedServicePrice
                        ? (packagePrice ? parseInt(packagePrice) : 0) +
                          (currentRoom ? currentRoom?.flat_price : 0) +
                          totalSelectedServicePrice
                        : 0}
                    </span>
                  </div>
                  <div className="tax-applicable">
                    <span>Tax Applicable</span>
                    <span>$0</span>
                  </div>
                </div>

                <div className="total">
                  <span>Total</span>

                  <span>
                    ${" "}
                    {packagePrice || currentRoom || totalSelectedServicePrice
                      ? (packagePrice ? parseInt(packagePrice) : 0) +
                        (currentRoom ? currentRoom?.flat_price : 0) +
                        totalSelectedServicePrice
                      : 0}
                  </span>
                </div>
              </div>
            </Spin>
          </>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (isSecondScreen) {
                    submitData("deactivated");
                  } else {
                    if (packageName) {
                      submitData("draft");
                    } else {
                      setIsNameError(true);
                    }
                  }
                }}
              >
                {isfirstScreen ? "Save as Draft" : "Save"}
              </button>
            </div>
            <div className="next-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (isSecondScreen) {
                    if (room) {
                      submitData("activated");
                    } else {
                      setIsRoomError(true);
                    }
                  } else {
                    if (!packageName) {
                      setIsNameError(true);
                    } else if (!packageDescription) {
                      setIsDescriptionError(true);
                    } else if (!packagePrice) {
                      setIsPriceError(true);
                    } else {
                      if (packageName && packageDescription && packagePrice) {
                        setIsNameError(false);
                        setIsDescriptionError(false);
                        setIsPriceError(false);
                        setIsSecondScreen(true);
                        setIsFirstScreen(false);
                      }
                    }
                  }
                }}
              >
                {isfirstScreen ? "Next" : "Save & Activate"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* <AddNewRoom open={isAddNewRoom} setOpen={setIsAddNewRoom} /> */}
      <AddImage open={isAddImage} setOpen={setIsAddImage} />
      <MessageModal
        open={isErrorModal}
        setOpen={setIsErrorModal}
        content={errorMessage}
      />
    </>
  );
};
export default AddNewPackage;
