import React, { useState, useEffect } from "react";
import "./Content.scss";
import { Button, Spin } from "antd";
import VenueCard from "./VenueCard";
import { Row, Col } from "antd";
import AddNewLocation from "../Modals/AddNewLocation";
import AddNewPackage from "../Modals/AddNewPackage";
import PackageCard from "./PackageCard";
import AddNewService from "../Modals/AddNewService.js";
import ServiceCard from "./ServiceCard";
import { Breadcrumb } from "antd";
import { ReactComponent as Back } from "./../../../assets/back.svg";
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { setLocations } from "../../../store/Location/LocationSlice";
import { setLocation } from "../../../store/Location/LocationSlice";

import VenueDetails from "./VenueDetails";
import axios from "axios";
const Content = ({ tabPosition, setTabPosition }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.venues.selectedVenue);
  const [isOpenPackageModal, setIsOpenPackageModal] = useState(false);
  const [isOpenServiceModal, setIsOpenServiceModal] = useState(false);
  const [isVenueDetails, setIsVenueDetails] = useState(false);
  const [isRoomDetails, setIsRoomDetails] = useState(false);
  const [venueId, setVenueId] = useState(null);
  const [venue, setVenue] = useState(null);

  // packages
  const [packages, setPackages] = useState([]);
  const [isPackageLoading, setIsPackageLoading] = useState(false);
  const [isPackageError, setIsPackageError] = useState(false);

  // services
  const [services, setServices] = useState([]);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const [isServiceError, setIsServiceError] = useState(false);

  // venues
  const [venues, setVenues] = useState([]);
  const [isVenueLoading, setIsVenueLoading] = useState(false);
  const [isVenueError, setIsVenueError] = useState(false);

  // add new location form
  const [locationName, setLocationName] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [locationState, setLocationState] = useState("");
  const [locationZipCode, setLocationZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  // Add new room form
  const [roomName, setRoomName] = useState("");
  const [price, setPrice] = useState("");
  const [maxGuest, setMaxGuest] = useState("");
  const [roomAmenities, setRoomAmenities] = useState("");
  const [roomSpaces, setRoomSpaces] = useState("");
  const [roomCeremonyTypes, setRoomCeremonyTypes] = useState("");

  const [activeTab, setActiveTab] = useState("All");

  const submitLocationAndRoom = () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/location/`,
          {
            name: locationName,
            address_line1: locationAddress,
            city: locationCity,
            state: locationState,
            zip_code: locationZipCode,
            phone: phone,
            email: email,
            // rooms: [
            //   {
            //     name: roomName,
            //     price: price,
            //     max_guest: maxGuest,
            //     amenities: roomAmenities,
            //     spaces: roomSpaces,
            //     ceremony_types: roomCeremonyTypes,
            //   },
            // ],
          },
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          },
        )
        .then((res) => {
          try {
            axios
              .post(`${process.env.REACT_APP_BASE_API_URL}/api/company/room/`, {
                name: roomName,
                flat_price: price,
                max_guests: maxGuest,
                spaces: roomSpaces.split(","),
                amenities: roomAmenities.split(","),
                ceremony_types: roomCeremonyTypes.split(","),
              })
              .then((res) => {
                console.log(res);
              });
          } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
              window.location.href = "/server-error";
              console.log("Redirecting to server error page...");
            } else {
              console.log(error);
            }
          }

          setOpen(false);
          setLocationName("");
          setLocationAddress("");
          setLocationCity("");
          setLocationState("");
          setLocationZipCode("");
          setPhone("");
          setEmail("");
          setRoomName("");
          setPrice("");
          setMaxGuest("");
          setRoomAmenities("");
          setRoomSpaces("");
          setRoomCeremonyTypes("");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getPackages = (status) => {
    try {
      let url = `${
        process.env.REACT_APP_BASE_API_URL
      }/api/company/package/?location=${location?.id}${
        status ? "&status=" + status : ""
      }`;
      setIsPackageLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          setPackages(res.data.results);
          setIsPackageLoading(false);
        });
    } catch (error) {
      setIsPackageLoading(false);
      setIsPackageError(true);
    }
  };
  const getServices = (status) => {
    try {
      let url;
      if (status === "True") {
        url = `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/?location=${location?.id}&is_active=True`;
      } else if (status === "False") {
        url = `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/?location=${location?.id}&is_active=False`;
      } else {
        url = `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/?location=${location?.id}`;
      }
      setIsServiceLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          setServices(res.data.results);
          setIsServiceLoading(false);
        });
    } catch (error) {
      setIsServiceLoading(false);
      setIsServiceError(true);
    }
  };

  const getVenues = (currentOpenId) => {
    try {
      setIsVenueLoading(true);
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/api/company/location/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          setVenues(res.data.results);
          dispatch(setLocations(res.data.results));
          dispatch(setLocation(res.data.results[0]));
          setIsVenueLoading(false);
          if (currentOpenId) {
            const current = res.data.results.find(
              (item) => item.id === currentOpenId,
            );
            setVenue(current);
            const oldRoom = JSON.parse(localStorage.getItem("currentRoom"));
            if (oldRoom && isRoomDetails) {
              const getUpdatedRoom = current.rooms.find(
                (item) => item.id === oldRoom?.id,
              );
              localStorage.setItem(
                "currentRoom",
                JSON.stringify(getUpdatedRoom),
              );
            }
          }
        });
    } catch (error) {
      setIsVenueLoading(false);
      setIsVenueError(true);
    }
  };

  useEffect(() => {
    if (tabPosition === "Packages") {
      getPackages();
    }
    if (tabPosition === "Services") {
      getServices();
    }
    if (tabPosition === "Venues") {
      getVenues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabPosition]);

  useEffect(() => {
    if (location) {
      getServices();
      getPackages();
    }
  }, [location]);

  useEffect(() => {
    if (tabPosition === "Packages") {
      if (activeTab === "All") {
        getPackages();
      } else if (activeTab == "Active") {
        getPackages("activated");
      } else if (activeTab === "Inactive") {
        getPackages("deactivated");
      } else {
        getPackages("draft");
      }
    } else if (tabPosition === "Services") {
      if (activeTab === "All") {
        getServices();
      } else if (activeTab == "Active") {
        getServices("True");
      } else if (activeTab === "Inactive") {
        getServices("False");
      } else {
        getServices();
      }
    }
  }, [activeTab]);

  const setIsVenueDetailsMenu = () => {
    setIsVenueDetails(false);
  };
  return (
    <>
      <Menu
        tabPosition={tabPosition}
        setTabPosition={setTabPosition}
        setIsVenueDetailsMenu={setIsVenueDetailsMenu}
      />
      {tabPosition === "Venues" && (
        <>
          <div className="content-wrapper">
            <div className="top">
              {isVenueDetails && !isRoomDetails ? (
                <>
                  <span className="left">
                    <Back
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsVenueDetails(false);
                      }}
                    />

                    <Breadcrumb
                      items={[
                        {
                          title: <span className="title-prev"> Venues </span>,
                        },
                        {
                          title: (
                            <span
                              className={`${
                                isVenueDetails && !isRoomDetails
                                  ? "title-2"
                                  : "title-prev"
                              }`}
                            >
                              {" "}
                              {venue?.name}{" "}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </span>
                </>
              ) : isRoomDetails && !isVenueDetails ? (
                <>
                  <span className="left">
                    <Back
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsVenueDetails(false);
                        setIsRoomDetails(false);
                      }}
                    />

                    <Breadcrumb
                      items={[
                        {
                          title: (
                            <span
                              className="title-prev"
                              onClick={() => {
                                setIsVenueDetails(false);
                                setIsRoomDetails(false);
                              }}
                            >
                              {" "}
                              Venues{" "}
                            </span>
                          ),
                        },
                        {
                          title: (
                            <span
                              onClick={() => {
                                setIsRoomDetails(false);
                                setIsVenueDetails(true);
                              }}
                              className={`${
                                isVenueDetails && !isRoomDetails
                                  ? "title-2"
                                  : "title-prev"
                              }`}
                            >
                              {" "}
                              {venue?.name}{" "}
                            </span>
                          ),
                        },
                        {
                          title: (
                            <span className={isRoomDetails ? "title-2" : ""}>
                              {" "}
                              {venue?.name}{" "}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </span>
                </>
              ) : (
                <div className="title">Venues</div>
              )}

              {!isVenueDetails && !isRoomDetails ? (
                <div className="button">
                  <Button
                    size="large"
                    className="btn"
                    onClick={() => setOpen(true)}
                  >
                    Add New Venue
                    {/* sprint 19 Add New Locations & Rooms */}
                  </Button>
                </div>
              ) : null}
            </div>

            {isVenueDetails || isRoomDetails ? (
              <VenueDetails
                venueId={venueId}
                venueData={venue}
                setIsRoomDetails={setIsRoomDetails}
                isRoomDetails={isRoomDetails}
                setIsVenueDetails={setIsVenueDetails}
                getVenues={getVenues}
              />
            ) : (
              <div className="venues">
                <Row gutter={[16, 16]}>
                  {isVenueLoading ? (
                    <div className="loading">
                      <Spin size="large" />
                    </div>
                  ) : !isVenueError ? (
                    venues.map(
                      (item) =>
                        !item?.is_deleted && (
                          <Col
                            key={item.id}
                            sm={24}
                            md={12}
                            lg={8}
                            xl={8}
                            xxl={6}
                          >
                            <VenueCard
                              setIsVenueDetails={setIsVenueDetails}
                              venueData={item}
                              setVenueId={setVenueId}
                              setVenue={setVenue}
                              getVenues={getVenues}
                            />
                          </Col>
                        ),
                    )
                  ) : (
                    <div className="error">Something went wrong</div>
                  )}
                </Row>
              </div>
            )}
          </div>
          <AddNewLocation getVenues={getVenues} open={open} setOpen={setOpen} />
        </>
      )}
      {tabPosition === "Packages" && (
        <>
          <div className="content-wrapper">
            <div className="top">
              <div className="title">
                <div className="package-btn-wrapper">
                  <button
                    onClick={() => setActiveTab("All")}
                    className={`package-menu-btn-left-most ${
                      activeTab === "All" ? "package-menu-btn-active" : ""
                    } `}
                  >
                    All
                  </button>
                  <button
                    className={`package-menu-btn-center ${
                      activeTab === "Active" ? "package-menu-btn-active" : ""
                    }`}
                    onClick={() => setActiveTab("Active")}
                  >
                    Active
                  </button>
                  <button
                    className={`package-menu-btn-center ${
                      activeTab === "Inactive" ? "package-menu-btn-active" : ""
                    }`}
                    onClick={() => setActiveTab("Inactive")}
                  >
                    Inactive
                  </button>
                  <button
                    className={`package-menu-btn-right-most ${
                      activeTab === "Draft" ? "package-menu-btn-active" : ""
                    }`}
                    onClick={() => setActiveTab("Draft")}
                  >
                    Draft
                  </button>
                </div>
              </div>
              <div className="button">
                <Button
                  size="large"
                  className="btn"
                  onClick={() => setIsOpenPackageModal(true)}
                >
                  Add Packages
                </Button>
              </div>
            </div>

            <div className="packages">
              {isPackageLoading ? (
                <div className="loading">
                  <Spin size="large" />
                </div>
              ) : !isPackageError ? (
                <Row gutter={[16, 16]}>
                  {packages.map((item) => (
                    <Col key={item.id} sm={24} md={12} lg={8} xl={8} xxl={6}>
                      <PackageCard
                        packageData={item}
                        activeTab={activeTab}
                        getPackages={getPackages}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="error">Something went wrong</div>
              )}
            </div>
          </div>
          <AddNewPackage
            open={isOpenPackageModal}
            getPackages={getPackages}
            setOpen={setIsOpenPackageModal}
          />
        </>
      )}

      {tabPosition === "Services" && (
        <>
          <div className="content-wrapper">
            <div className="top">
              <div className="title">
                <div className="service-btn-wrapper">
                  <button
                    onClick={() => setActiveTab("All")}
                    className={`service-menu-btn-left-most ${
                      activeTab === "All" ? "service-menu-btn-active" : ""
                    } `}
                  >
                    All
                  </button>
                  <button
                    className={`service-menu-btn-center ${
                      activeTab === "Active" ? "service-menu-btn-active" : ""
                    }`}
                    onClick={() => setActiveTab("Active")}
                  >
                    Active
                  </button>
                  <button
                    className={`service-menu-btn-right-most ${
                      activeTab === "Inactive" ? "service-menu-btn-active" : ""
                    }`}
                    onClick={() => setActiveTab("Inactive")}
                  >
                    Inactive
                  </button>
                </div>
              </div>
              <div className="button">
                <Button
                  size="large"
                  className="btn"
                  onClick={() => setIsOpenServiceModal(true)}
                >
                  Add Services
                </Button>
              </div>
            </div>

            <div className="services">
              {isServiceLoading ? (
                <div className="loading">
                  <Spin size="large" />
                </div>
              ) : !isServiceError ? (
                <Row gutter={[16, 16]}>
                  {services.map((item) => (
                    <Col key={item.id} sm={24} md={12} lg={8} xl={8} xxl={6}>
                      <ServiceCard
                        serviceData={item}
                        activeTab={activeTab}
                        getServices={getServices}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="error">Something went wrong</div>
              )}
            </div>
          </div>
          <AddNewService
            open={isOpenServiceModal}
            getServices={getServices}
            setOpen={setIsOpenServiceModal}
          />
        </>
      )}

      {tabPosition === "Team" && (
        <>
          <div className="content-wrapper">
            <div className="top">
              <div className="title">Team</div>
              <div className="button">
                <Button
                  size="large"
                  className="btn"
                  onClick={() => setOpen(true)}
                >
                  Add Team
                </Button>
              </div>
            </div>
          </div>
          <AddNewLocation open={open} setOpen={setOpen} />
        </>
      )}
      {tabPosition === "Automation" && (
        <>
          <div className="content-wrapper">
            <div className="top">
              <div className="title">Automation</div>
              <div className="button">
                <Button
                  size="large"
                  className="btn"
                  onClick={() => setOpen(true)}
                >
                  Add Automation
                </Button>
              </div>
            </div>
          </div>
          <AddNewLocation
            open={open}
            setOpen={setOpen}
            submitLocationAndRoom={submitLocationAndRoom}
          />
        </>
      )}
    </>
  );
};

export default Content;
