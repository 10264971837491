import React, { useEffect, useState } from 'react';
import { Typography, Card, Space, Row, Col, Form, Select, Grid, Tooltip } from "antd";
import { InfoOutlined, CheckOutlined } from "@ant-design/icons";

const { useBreakpoint } = Grid;

const PricingModel = ({ setPricingModel, draftDetails }) => {
    const screens = useBreakpoint();
    const { Title } = Typography;
    const [form] = Form.useForm();

    useEffect(() => {
        // Check if draftDetails has pricing model data
        const initialValues = {
            rooms: draftDetails.rooms_pm || "PER_PERSON",
            services: draftDetails.addons_pm || "PER_PERSON", // Update this line as needed
            packages: draftDetails.packages_pm || "PER_PERSON",
        };

        form.setFieldsValue(initialValues);
        setPricingModel(initialValues);
    }, [form, setPricingModel, draftDetails]);

    const handleFormChange = (changedValues, allValues) => {
        setPricingModel(allValues);
    };

    return (
        <div>
            <Card style={{ marginTop: "20px" }}>
                <Space style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <Title level={4}>
                        Pricing Model
                        &nbsp;<Tooltip placement="top" title={"Choose pricing Option. Per Person allows you to charge based on guest count (guest capacity multiply by price). Fixed Price does not consider guest count."}>
                            <InfoOutlined
                                className='add-input-field'
                                style={{ marginBottom: '10px', cursor: 'pointer' }}
                            />
                        </Tooltip>
                    </Title>
                    {
                        screens.xs ? (
                            <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                            </div>
                        ) : null
                    }
                </Space>
                <Form
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                    onValuesChange={handleFormChange}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={screens.xs ? 24 : 11}>
                            <Title style={{ fontSize: "16px" }}>Room<span style={{ color: 'red' }}>*</span></Title>
                            <Form.Item
                                name="rooms"
                                rules={[
                                    {
                                        required: true,
                                        message: "Room name is required!",
                                    },
                                ]}
                            >
                                <Select size="large" style={{ width: "100%" }}>
                                    <Select.Option value="PER_PERSON">Price Per Person</Select.Option>
                                    <Select.Option value="FLAT_RATE">Flat Price</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={screens.xs ? 24 : 11}>
                            <Title style={{ fontSize: "16px" }}>Services<span style={{ color: 'red' }}>*</span></Title>
                            <Form.Item
                                name="services"
                                rules={[
                                    {
                                        required: true,
                                        message: "Service name is required!",
                                    },
                                ]}
                            >
                                <Select size="large" style={{ width: "100%" }}>
                                    <Select.Option value="PER_PERSON">Price Per Person</Select.Option>
                                    <Select.Option value="FLAT_RATE">Flat Price</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={screens.xs ? 24 : 11}>
                            <Title style={{ fontSize: "16px" }}>Packages<span style={{ color: 'red' }}>*</span></Title>
                            <Form.Item
                                name="packages"
                                rules={[
                                    {
                                        required: true,
                                        message: "Package name is required!",
                                    },
                                ]}
                            >
                                <Select size="large" style={{ width: "100%" }}>
                                    <Select.Option value="PER_PERSON">Price Per Person</Select.Option>
                                    <Select.Option value="FLAT_RATE">Flat Price</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
}

export default PricingModel;