import { Modal, DatePicker, Upload, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "./editPackageForm.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Select, Row, Col } from "antd";
import MessageModal from "../MessageModal";
import dayjs from "dayjs";
import axios from "axios";
import AddImage from "../AddImage";
import { useSelector } from "react-redux";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const { RangePicker } = DatePicker;
const EditPackageForm = ({ open, setOpen, getPackages, packageData }) => {
  const location = useSelector((state) => state.venues.selectedVenue);
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadImageUrl, setUploadImageURL] = useState("");
  const [rooms, setRooms] = useState([]);
  const [services, setServices] = useState(
    packageData?.addons ? packageData?.addons : [],
  );
  const [room, setRoom] = useState(null);
  const [service, setService] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [packageName, setPackageName] = useState(packageData?.name);
  const [packageDescription, setPackageDescription] = useState(
    packageData?.description,
  );
  const [isSpin, setIsSpin] = useState(false);
  const [packagePrice, setPackagePrice] = useState(packageData?.price);
  const [packageDuration, setPackageDuration] = useState(
    packageData?.duration_minutes,
  );
  const [packageStartDate, setPackageStartDate] = useState("");
  const [packageEndDate, setPackageEndDate] = useState("");
  const [totalSelectedServicePrice, setTotalSelectedServicePrice] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);

  const [isAddImage, setIsAddImage] = useState(false);
  const onChange = (date, dateString) => {
    setPackageStartDate(dateString[0]);
    setPackageEndDate(dateString[1]);
    setSelectedDateRange(date);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        setFile(info.file.originFileObj);
        setUploadImageURL(URL.createObjectURL(info.file.originFileObj));
      } else if (status === "error") {
      }
    },

    onDrop(e) {
      console.log("Dropped files", e);
    },
  };
  

  const getRooms = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/room/?location=${location?.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      )
      .then((res) => {
        setRooms(res.data.results);
      });
  };

  const getServices = () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/addon/?location=${location?.id}`,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          },
        )
        .then((res) => {
          setServices(res.data.results);
        });
    } catch (error) {}
  };
  const getService = (id) => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/api/company/addon/${id}/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          setCurrentService(res.data);
        });
    } catch (error) {}
  };
  const getRoom = (id) => {
    if (!id) return;
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/api/company/room/${id}/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        })
        .then((res) => {
          setCurrentRoom(res.data);
          // setServices(res.data.results);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getRooms();
    getServices();
  }, []);

  useEffect(() => {
    if (packageData) {
      getRoom(packageData?.room?.id);
      setService(packageData?.addons);
      setRoom(packageData?.room?.id);
    }
  }, [packageData]);

  const submitData = (status) => {
    setIsSpin(true);

    const formData = new FormData();
    if (status !== "draft") {
      if (packageName) {
        formData.append("name", packageName);
      }
      if (packageDescription) {
        formData.append("description", packageDescription);
      }
      if (packagePrice) {
        formData.append("price", packagePrice);
      }
      if (packageStartDate) {
        formData.append("start_date", packageStartDate);
      }
      if (packageEndDate) {
        formData.append("end_date", packageEndDate);
      }
      if (location?.id) {
        formData.append("location", location?.id);
      }
      if (room) {
        formData.append("room", room);
      }
      if (service) {
        formData.append("addons", JSON.stringify(service.map((s) => s.id)));
      }
      if (file) {
        formData.append("image", file);
      }
      if (packageDuration) {
        formData.append("duration_minutes", packageDuration);
      }
      if (status) {
        formData.append("status", status);
      }
    } else {
      if (packageName) {
        formData.append("name", packageName);
      }
      if (packageDescription) {
        formData.append("description", packageDescription);
      }
      if (packagePrice) {
        formData.append("price", packagePrice);
      }
      if (packageStartDate) {
        formData.append("start_date", packageStartDate);
      }
      if (packageEndDate) {
        formData.append("end_date", packageEndDate);
      }
      if (location?.id) {
        formData.append("location", location?.id);
      }
      if (room) {
        formData.append("room", room);
      }
      if (service) {
        formData.append("addons", JSON.stringify(service.map((s) => s.id)));
      }
      if (file) {
        formData.append("image", file);
      }
      if (packageDuration) {
        formData.append("duration_minutes", packageDuration);
      }
      if (status) {
        formData.append("status", status);
      }
    }
    axios
      .put(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/package/${packageData?.id}/`,
        formData,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      )
      .then((res) => {
        setIsSpin(false);
        setUploadImageURL("");
        setOpen(false);
        getPackages();
      })
      .catch((eror) => {
        setErrorMessage(eror.response.data.message[0]?.name[0]);
        setIsSpin(false);
        setIsErrorModal(true);

        // setErrorMessage()
      });
  };

  useEffect(() => {
    const serviceValue = service?.reduce((a, v) => (a = a + v.price), 0);
    setTotalSelectedServicePrice(serviceValue);
  }, [service]);

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="edit-package"
        closable={false}
        onCancel={() => setOpen(false)}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen) {
                  setOpen(false);
                  setIsNameError(false);
                  setIsDescriptionError(false);
                  setIsPriceError(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Add New Package</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setOpen(false);
                setFile(null);
                setUploadImageURL(null);
                setPackageEndDate("");
                setPackageStartDate("");
                setPackagePrice("");
                setPackageDescription("");
                setPackageName("");
                setPackageDuration("");
                setIsNameError(false);
                setIsDescriptionError(false);
                setIsPriceError(false);
                setService([]);
                setRoom(null);
                setCurrentRoom(null);
                setCurrentService(null);
                setSelectedDateRange(null);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <div className="content-edit-company-2">
            <div className="content-edit-company-input-wrapper">
              <Input
                placeholder="Package Name*"
                value={packageName}
                onChange={(e) => {
                  setPackageName(e.target.value);
                }}
                size="large"
                required
              />
              {isNameError ? (
                <span style={{ color: "red" }}>Package Name is required</span>
              ) : null}
            </div>
            <div className="content-edit-company-input-wrapper">
              <Input
                placeholder="Description"
                value={packageDescription}
                onChange={(e) => {
                  setPackageDescription(e.target.value);
                }}
                size="large"
                required
              />
              {isDescriptionError ? (
                <span style={{ color: "red" }}>
                  Package Description is required
                </span>
              ) : null}
            </div>

            <Row gutter={[16, 16]}>
              <Col md={12}>
                <div className="content-edit-company-input-wrapper">
                  <Input
                    placeholder="Price                                    $0.00"
                    size="large"
                    value={packagePrice}
                    onChange={(e) => {
                      setPackagePrice(e.target.value);
                    }}
                  />
                  {isPriceError ? (
                    <span style={{ color: "red" }}>
                      Package Price is required
                    </span>
                  ) : null}
                </div>
              </Col>
              <Col md={12}>
                <div className="content-edit-company-input-wrapper">
                  <Input
                    placeholder="Package Duration                     --"
                    size="large"
                    value={packageDuration}
                    onChange={(e) => {
                      setPackageDuration(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col md={24}>
                <div className="content-edit-company-input-wrapper">
                  <RangePicker
                    onChange={onChange}
                    // value={selectedDateRange}
                    defaultValue={
                      !selectedDateRange
                        ? [
                            dayjs(packageData?.start_date),
                            dayjs(packageData?.end_date),
                          ]
                        : selectedDateRange
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col sm={12}>
                {uploadImageUrl ? (
                  <Upload
                    {...props}
                    showUploadList={false}
                    accept=".jpg, .jpeg, .png"
                  >
                    <div className="add-new-img">
                      <img
                        src={uploadImageUrl}
                        alt="upload"
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Upload>
                ) : packageData?.image && !uploadImageUrl ? (
                  <Upload
                    {...props}
                    showUploadList={false}
                    accept=".jpg, .jpeg, .png"
                  >
                    <div className="add-new-img">
                      <img
                        src={packageData?.image}
                        alt="upload"
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </Upload>
                ) : (
                  <div className="add-new-img">
                    <span>Add Image</span>
                    <Upload
                      {...props}
                      showUploadList={false}
                      accept=".jpg, .jpeg, .png"
                    >
                      <button onClick={() => {}}>Add</button>
                    </Upload>
                  </div>
                )}
              </Col>
              <Col md={12}></Col>
            </Row>
            {/* 
            <Row gutter={[16, 16]}>
              <Col sm={12}>
                <div className="add-new-img">
                  <span>Add Image</span>
                  <button
                    onClick={() => {
                      setIsAddImage(true);
                      setOpen(false);
                    }}
                  >
                    Add
                  </button>
                </div>
              </Col>
              <Col md={12}></Col>
            </Row> */}
          </div>
        )}
        {isSecondScreen && (
          <>
            <Spin spinning={isSpin} delay={400}>
              <div className="content-second-screen">
                <Row gutter={[16, 16]}>
                  <Col md={24}>
                    <div className="content-edit-company-input-wrapper">
                      <Select
                        placeholder="Select Room to associate with"
                        style={{
                          width: "100%",
                        }}
                        onChange={(value) => {
                          setRoom(value);
                          getRoom(value);
                        }}
                        value={
                          currentRoom
                            ? {
                                value: currentRoom?.id,
                                label: currentRoom?.name,
                              }
                            : null
                        }
                        size="large" // onChange={handleChange}
                        options={rooms.map((room) => {
                          return {
                            value: room.id,
                            label: room.name,
                          };
                        })}
                      />
                    </div>
                  </Col>
                  <Col md={24}>
                    <div className="content-edit-company-input-wrapper">
                      <Select
                        placeholder="Select Services to associate with"
                        style={{
                          width: "100%",
                        }}
                        mode="multiple"
                        defaultValue={service.map((s) => {
                          return {
                            value: s.id,
                            label: s.name,
                          };
                        })}
                        onChange={(value) => {
                          setService(value.map((v) => JSON.parse(v)));
                        }}
                        size="large" // onChange={handleChange}
                        options={services.map((service) => {
                          return {
                            value: JSON.stringify(service),
                            label: service.name,
                          };
                        })}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="max-guests">
                  <span>Max guest</span>
                  <span className="value-box">
                    {currentRoom?.max_guests ? currentRoom?.max_guests : 0}
                  </span>
                </div>

                <div className="sub-total-wrapper">
                  <div className="sub-total">
                    <span>Subtotal</span>
                    <span>
                      $
                      {packagePrice || currentRoom || currentService
                        ? (packagePrice ? parseInt(packagePrice) : 0) +
                          (currentRoom ? currentRoom?.flat_price : 0) +
                          (totalSelectedServicePrice
                            ? totalSelectedServicePrice
                            : 0) +
                          (currentService ? currentService?.price : 0)
                        : 0}
                    </span>
                  </div>
                  <div className="tax-applicable">
                    <span>Tax Applicable</span>
                    <span>$0</span>
                  </div>
                </div>

                <div className="total">
                  <span>Total</span>

                  <span>
                    ${" "}
                    {packagePrice || currentRoom || currentService
                      ? (packagePrice ? parseInt(packagePrice) : 0) +
                        (currentRoom ? currentRoom?.flat_price : 0) +
                        (totalSelectedServicePrice
                          ? totalSelectedServicePrice
                          : 0) +
                        (currentService ? currentService?.price : 0)
                      : 0}
                  </span>
                </div>
              </div>
            </Spin>
          </>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (isSecondScreen) {
                    submitData("deactivated");
                  } else {
                    if (packageName) {
                      submitData("draft");
                    } else {
                      setIsNameError(true);
                    }
                  }
                }}
              >
                {isfirstScreen ? "Save as Draft" : "Save"}
              </button>
            </div>
            <div className="next-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (isSecondScreen) {
                    if (room) {
                      submitData("activated");
                    }
                  } else {
                    if (!packageName) {
                      setIsNameError(true);
                    } else if (!packageDescription) {
                      setIsDescriptionError(true);
                    } else if (!packagePrice) {
                      setIsPriceError(true);
                    } else {
                      if (packageName && packageDescription && packagePrice) {
                        setIsNameError(false);
                        setIsDescriptionError(false);
                        setIsPriceError(false);
                        setIsSecondScreen(true);
                        setIsFirstScreen(false);
                      }
                    }
                  }
                }}
              >
                {isfirstScreen ? "Next" : "Save & Activate"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* <AddNewRoom open={isAddNewRoom} setOpen={setIsAddNewRoom} /> */}
      <AddImage open={isAddImage} setOpen={setIsAddImage} />
      <MessageModal
        open={isErrorModal}
        setOpen={setIsErrorModal}
        content={errorMessage}
      />
    </>
  );
};
export default EditPackageForm;
