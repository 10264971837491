import { useState } from "react";
import { Form, Input, Button, Card } from "antd";
import axiosInstance from "../../../common/axiosInstance";
import "./PaswordChange.scss";
import toast from "react-hot-toast";
import PasswordCheckList from "../../../Authorization/Register/PassowrdCheckList";
import PasswordStrengthBar from "react-password-strength-bar";
const PasswordChange = () => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState(0);
  const [passwordValue, setPasswordValue] = useState("");
  const [strength, setStrength] = useState(0);
  const [showPasswordStrengthModal, setShowPasswordStrengthModal] = useState(false);

  const handleSubmit = (values) => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      console.error("Access token not found in local storage. Please log in.");
      return;
    }

    const data = {
      old_password: values.oldPassword,
      new_password: values.newPassword,
      retype_password: values.confirmPassword,
    };

    const config = {
      headers: {
        Authorization: `JWT ${access_token}`,
      },
    };

    axiosInstance
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/auth/password/change/`,
        data,
        config,
      )
      .then((response) => {
        toast.success("Password change successful", { duration: 3000 });
      })
      .catch((error) => {
        if (error.response) {
          const errorData = error.response.data;
          if (
            errorData.message &&
            Array.isArray(errorData.message) &&
            errorData.message.length > 0
          ) {
            const errorMessage = errorData.message[0];
            if (
              errorMessage.old_password &&
              Array.isArray(errorMessage.old_password) &&
              errorMessage.old_password.length > 0
            ) {
              const oldPasswordErrorMessage = errorMessage.old_password[0];
              toast.error(oldPasswordErrorMessage, { duration: 3000 });
            } else {
              toast.error("Password change failed", { duration: 3000 });
            }
          } else {
            toast.error("Password change failed", { duration: 3000 });
          }
        } else {
          toast.error("Password change failed", { duration: 3000 });
        }
        console.error("Password change failed", error);
      });
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject("Please enter your New password.");
    }

    if (value.length < 8) {
      return Promise.reject("Password is too short");
    }

    // const passwordRegex = /^(?=.*\d)(?=.*[A-Z])[A-Za-z\d@$!%*?&#.^()_]{8,}$/;
    // if (!passwordRegex.test(value)) {
    //   return Promise.reject("Password should contain at least one digit and one uppercase letter");
    // }

    return Promise.resolve();
  };

  //password Strength cheker:::
  const [passwordErrors, setPasswordErrors] = useState({
    length: false,
    hasUpperCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  });

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: passwordValue.length >= 8,
      hasUpperCase: /[A-Z]+/.test(passwordValue),
      hasDigit: /[0-9]+/.test(passwordValue),
    };

    setPassword(passwordValue);
    setPasswordValue(passwordValue);
    setPasswordErrors(strengthChecks);

    const newStrength = Object.values(strengthChecks).filter(Boolean).length;
    setStrength(newStrength);
    const borderColor = getPasswordBorderColor(newStrength);
    setShowPasswordStrengthModal(true);
  };

  const getPasswordBorderColor = (strength) => {
    const barColors = ["", "red", "orange", "blue", "#25c281"];
    return barColors[Math.min(strength, barColors.length - 1)];
  };

  const handlePasswordBlur = (e) => {
    const isClickOutsideCard =
      !e.relatedTarget || !e.relatedTarget.closest(".password-strength-modal");
    if (isClickOutsideCard) {
      setTimeout(() => {
        setShowPasswordStrengthModal(false);
      }, 0);
    }
  };
  return (
    <div className="password-container">
      <Card title="Change Password" style={{ width: 800 }}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Please enter your old password" },
            ]}
          >
            <Input.Password placeholder="Enter your old password" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              // { required: true, message: "Please enter your new password" },
              { validator: validatePassword },
            ]}
          >
            <Input.Password
              placeholder="Enter your new password"
              value={password}
              onChange={({ target }) => handlePassword(target.value)}
              style={{ borderColor: getPasswordBorderColor(strength) }}
              onBlur={(e) => handlePasswordBlur(e)}
            />
          </Form.Item>
          {showPasswordStrengthModal && (
            <div className="overlay">
              <div className="password-strength-modal">
                <p>Password Strength</p>
                <PasswordStrengthBar password={passwordValue} />
                <PasswordCheckList passwordErrors={passwordErrors} />
              </div>
            </div>
          )}
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Mismatched passwords");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm your new password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default PasswordChange;
