import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Layout, Card, Button, Spin, Row, Col, Select, DatePicker } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import "./AdminDB.css";
import icon1 from "./Assets/Square Icon Badge (1).png";
import icon2 from "./Assets/Square Icon Badge (2).png";
import icon3 from "./Assets/Square Icon Badge (3).png";
import icon4 from "./Assets/Square Icon Badge.png";
import CalendarEvents from "./comps/CalendarEvents";
import ProgressCard from "./ProgressCard";
import { useNavigate } from "react-router-dom";
// import LineChart from "./comps/LineChart";

import * as XLSX from "xlsx";
import axios from "../network/axios";
import TaskSummary from "./TaskSummary";
import Chart from "./comps/Chart";

const AdminDB = () => {
  const location = useSelector((state) => state.venues.selectedVenue);
  const { RangePicker } = DatePicker;
  const [userInfo, setUserInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [TaskEventFilter, setTaskEventFilter] = useState("Tasks");
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [overview, setOverview] = useState(null);
  const [filterType, setFilterType] = useState("monthly");
  const [rangVal, setRangVal] = useState("");
  const scrollWrapper = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem("planspace_user_obj"));
    setUserInfo(getUser.data || getUser);
  }, [localStorage.getItem("planspace_user_obj")]);

  const getOverview = async () => {
    setLoading(true);
    try {
      let url = `${process.env.REACT_APP_BASE_API_URL}/api/event/dashboard?location=${location?.id}&type=${filterType}${rangVal && `&start_date=${rangVal[0] || ""}&end_date=${rangVal[1] || ""}`}`;
      await axios.get(url).then((result) => {
        setOverview(result.data.data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        // Navigate to the server error page
        navigate("/server-error");
      }
    }
  };

  // dates are being updated here
  useEffect(() => {
    if (location?.id && filterType !== "customDates") {
      getOverview();
    } else if (filterType === "customDates" && rangVal.length && location?.id) {
      getOverview();
    }
  }, [location, filterType, rangVal]);

  const rangPicker = (val, strVal) => {
    const [first, second] = strVal;
    if (first && second) {
      setRangVal(strVal);
    }
  };

  const filterMonth = (value) => {
    setFilterType(value);
    if (value === "customDates") {
      setShowCustomDate(true);
    } else if (value !== "customDates") {
      setShowCustomDate(false);
      setRangVal("");
    }
  };

  const removeTags = (str) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = str;
    return tempElement.textContent;
  };

  const exportData = (tasks, events) => {
    if (!tasks || !events) {
      toast.error("No data available");
    } else {
      const tasksUpdate = tasks?.map((task) => {
        task.is_automated = task.is_automated ? "Automated" : "Manual";
        let date = new Date(task.due_date);
        task.due_date = date.toLocaleString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        task.description = removeTags(task.description);
        return task;
      });
      /* convert the data to a sheet */
      const tasksSheet = XLSX.utils.json_to_sheet(tasksUpdate);
      const eventsSheet = XLSX.utils.json_to_sheet(events);

      /* create a new workbook */
      const workbook = XLSX.utils.book_new();

      var firstRow = [
        { v: "Title", t: "s", s: { font: { bold: true } } },
        { v: "Event Date", t: "s", s: { font: { bold: true } } },
        { v: "Status", t: "s", s: { font: { bold: true } } },
        { v: "Phone Number", t: "s", s: { font: { bold: true } } },
        { v: "Email", t: "s", s: { font: { bold: true } } },
      ];

      XLSX.utils.sheet_add_aoa(eventsSheet, [firstRow], { origin: 0 });

      var tasksRow = [
        { v: "Task Name", t: "s", s: { font: { bold: true } } },
        { v: "Task Description", t: "s", s: { font: { bold: true } } },
        { v: "Task Type", t: "s", s: { font: { bold: true } } },
        { v: "Due Date", t: "s", s: { font: { bold: true } } },
      ];

      XLSX.utils.sheet_add_aoa(tasksSheet, [tasksRow], { origin: 0 });

      /* add the sheet to the workbook */
      XLSX.utils.book_append_sheet(workbook, tasksSheet, "Tasks");
      XLSX.utils.book_append_sheet(workbook, eventsSheet, "Events");

      /* generate a downloadable file */
      XLSX.writeFile(
        workbook,
        `tasks-and-events${filterType !== "customDates" && "-" + filterType}-overview.xlsx`,
      );
    }
  };

  return (
    <div className="main_wrapper" ref={scrollWrapper}>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout style={{ backgroundColor: "#f9fdff" }}>
          <Layout.Content>
            <Card
              className="dashboard-card"
              style={{ marginTop: "1rem", backgroundColor: "#FFFFFF" }}
            >
              <h5 style={{ fontSize: "32px" }}>
                Welcome Back, {userInfo?.full_name || ""}
              </h5>
              <div
                className="button-container"
                style={{
                  display: "flex",
                  gap: 8,
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  type={TaskEventFilter === "Tasks" ? "primary" : "default"}
                  onClick={() => setTaskEventFilter("Tasks")}
                >
                  Tasks
                </Button>
                <Button
                  type={TaskEventFilter === "Tasks" ? "default" : "primary"}
                  onClick={() => setTaskEventFilter("Events")}
                >
                  Events
                </Button>

                <Select
                  value={filterType}
                  onChange={filterMonth}
                  popupMatchSelectWidth={false}
                  options={[
                    {
                      label: "Daily",
                      value: "daily",
                    },
                    {
                      label: "Weekly",
                      value: "weekly",
                    },
                    {
                      label: "Monthly",
                      value: "monthly",
                    },
                    {
                      label: "Yearly",
                      value: "yearly",
                    },
                    {
                      value: "customDates",
                      label: "Custom Dates",
                    },
                  ]}
                />
                {showCustomDate && (
                  <RangePicker style={{ width: 250 }} onChange={rangPicker} />
                )}
                <Button
                  type="default"
                  icon={<ExportOutlined />}
                  onClick={() =>
                    exportData(
                      overview?.export_data?.tasks,
                      overview?.export_data?.events,
                    )
                  }
                >
                  Export
                </Button>
              </div>
            </Card>
            <Spin spinning={loading}>
              <Row gutter={[24, 24]} style={{ marginBlock: 24 }}>
                {TaskEventFilter === "Tasks" ? (
                  <>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#EDDAFF",
                        }}
                      >
                        <img src={icon4} alt="total" />
                        <h5
                          style={{
                            fontSize: "16px",
                            color: "rgba(136, 61, 207, 0.75)",
                          }}
                        >
                          All Tasks
                        </h5>
                        <h1
                          style={{
                            fontSize: "16px",
                            color: "rgba(136, 61, 207, 0.75)",
                          }}
                        >
                          {overview?.tasks?.total || 0}
                        </h1>
                      </Card>
                    </Col>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#cbc3f7",
                        }}
                      >
                        <img src={icon1} alt="manual" />
                        <h5
                          style={{
                            fontSize: "16px",
                            color: "rgba(50, 80, 255, 0.75)",
                          }}
                        >
                          Manual Tasks
                        </h5>
                        <h1
                          style={{
                            fontSize: "16px",
                            color: "rgba(50, 80, 255, 0.75)",
                          }}
                        >
                          {overview?.tasks?.manual || 0}
                        </h1>
                      </Card>
                    </Col>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#9accf5",
                        }}
                      >
                        <img src={icon2} alt="automated" />
                        <h5 style={{ fontSize: "16px", color: "#2BB2FE" }}>
                          Automated Tasks
                        </h5>
                        <h1 style={{ fontSize: "16px", color: "#2BB2FE" }}>
                          {overview?.tasks?.automated || 0}
                        </h1>
                      </Card>
                    </Col>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#9ef7d1",
                        }}
                      >
                        <img src={icon3} alt="completed" />
                        <h5 style={{ fontSize: "16px", color: "#43A047" }}>
                          Completed Tasks
                        </h5>
                        <h1 style={{ fontSize: "16px", color: "#43A047" }}>
                          {overview?.tasks?.completed || 0}
                        </h1>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#EDDAFF",
                        }}
                      >
                        <img src={icon4} alt="total" />
                        <h5
                          style={{
                            fontSize: "16px",
                            color: "rgba(136, 61, 207, 0.75)",
                          }}
                        >
                          All Events
                        </h5>
                        <h1
                          style={{
                            fontSize: "16px",
                            color: "rgba(136, 61, 207, 0.75)",
                          }}
                        >
                          {overview?.events?.total || 0}
                        </h1>
                      </Card>
                    </Col>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#cbc3f7",
                        }}
                      >
                        <img src={icon1} alt="manual" />
                        <h5
                          style={{
                            fontSize: "16px",
                            color: "rgba(50, 80, 255, 0.75)",
                          }}
                        >
                          In Progress Events
                        </h5>
                        <h1
                          style={{
                            fontSize: "16px",
                            color: "rgba(50, 80, 255, 0.75)",
                          }}
                        >
                          {overview?.events?.in_progress || 0}
                        </h1>
                      </Card>
                    </Col>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#9accf5",
                        }}
                      >
                        <img src={icon2} alt="automated" />
                        <h5 style={{ fontSize: "16px", color: "#2BB2FE" }}>
                          Scheduled Events
                        </h5>
                        <h1 style={{ fontSize: "16px", color: "#2BB2FE" }}>
                          {overview?.events?.scheduled || 0}
                        </h1>
                      </Card>
                    </Col>
                    <Col span={24} lg={12} xl={6}>
                      <Card
                        className="Dashboard-cards"
                        style={{
                          minHeight: "165px",
                          background: "#9ef7d1",
                        }}
                      >
                        <img src={icon3} alt="completed" />
                        <h5 style={{ fontSize: "16px", color: "#43A047" }}>
                          Pending Payments
                        </h5>
                        <h1 style={{ fontSize: "16px", color: "#43A047" }}>
                          {overview?.events?.pending_payments || 0}
                        </h1>
                      </Card>
                    </Col>
                  </>
                )}
              </Row>
            </Spin>
            {/* </Space> */}
            <Row gutter={[24, 24]}>
              <Col span={24} xl={16} xxl={18}>
                <Chart filter={filterType} rangVal={rangVal} />
              </Col>
              <Col span={24} xl={8} xxl={6}>
                {/* progressCard */}
                <ProgressCard />
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
              <Col span={24} xxl={16}>
                <CalendarEvents />
              </Col>
              {/* <Col span={24} xxl={8}>
                <TaskSummary />
              </Col> */}
            </Row>
          </Layout.Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AdminDB;
