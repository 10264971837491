import React, { useState, useEffect } from "react";
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Input, Select, Divider, Row, Col, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, InfoOutlined } from "@ant-design/icons";

import moment from "moment";
import { ReactComponent as Badge } from "../../../assets/icons/badge.svg";
import { serviceIcons } from "./utils";
import bgImage from "../../../assets/background.jpg";
import dummyPhoto from '../Assets/photo.png';
import Loader from "../../../common/Loader";

import "../EndUserStyles.scss";
const { useBreakpoint } = Grid;

const Packages = ({ setFilteredServices, packages, value, setSelectedPackage, pricingModel }) => {
  const screens = useBreakpoint();
  const { Title, Text } = Typography;
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // const updatedPckages = packages?.length
  //   ? packages.map((item) => ({ label: item.name, value: item.id, price: item.flat_price }))
  //   : [];
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursText = hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : "";
    const minutesText =
      remainingMinutes > 0
        ? `${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`
        : "";

    if (hoursText && minutesText) {
      return `${hoursText} and ${minutesText}`;
    } else {
      return hoursText || minutesText || "-";
    }
  };


  // useEffect(() => {
  //   if (value) {
  //     const found = packages?.find((item) => item.id === value);
  //     setSelected(found);
  //     setFilteredServices(found);
  //   }
  // }, [value]);

  const dataSource = packages?.map((item, index) => ({
    key: index,
    id: item.id,
    name: item?.name,
    description: item.description,
    image: item?.images?.length ? item?.images[0]?.image : dummyPhoto,
    price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,

  }));
  const handleToggleDescription = (packageId) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [packageId]: !prev[packageId],
    }));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: 40,
      render: (text) => (
        <img
          src={text}
          alt="package"
          style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px" }}
        />
      ),
    },
    {
      title: '',
      width: "50%",
      key: 'packges',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
          <h4 style={{ margin: 0 }}>{record.name}</h4>
          <p style={{ margin: 0 }}>
            {expandedDescriptions[record.key]
              ? record.description
              : `${record.description?.substring(0, 45)}${record.description?.length > 45 ? '...' : ''}`}
            {record.description?.length > 45 && (
              <span
                style={{ color: "#1890ff", cursor: "pointer", marginLeft: "5px" }}
                onClick={() => handleToggleDescription(record.key)}
              >
                {expandedDescriptions[record.key] ? 'Show Less' : 'Read More'}
              </span>
            )}
          </p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <h4 style={{ margin: 0 }}>${Number(text || 0).toFixed(2)}</h4>,
    },
    {
      title: 'Select',
      key: 'select',
      width: 40,
      render: (text, record) => (
        <Checkbox
          checked={value.some((selectedPackage) => selectedPackage.id === record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedPackage((prevSelected) =>
                prevSelected[0]?.id === record.id ? [] : [record]
              );
            } else {
              setSelectedPackage([]);
            }
          }}
        />


      ),
    },


  ];

  return (
    <>
      <Card style={{ marginTop: screens.xs ? "10px" : "20px" }}>
        <Space
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>Packages</Title>
          <Tooltip
            placement="top"
            title={`Pricing will be calculated as ${pricingModel === "PER_PERSON" ? "Per Person" : "Flat Price"}`}
          >
            <InfoOutlined
              className="add-input-field"
              style={{ marginBottom: '10px', cursor: 'pointer', marginLeft: '2px' }}
            />
          </Tooltip>
        </Space>
        <div
          className="events-details"
          style={{ marginTop: screens.xs ? "10px" : "0" }}
        >
          <p
            className="event-head"
            style={{ color: "#667085", pointerEvents: "none" }}
          >
            Select Packages to add
          </p>
        </div>
        <div className="item-details" style={{ height: "140px" }}>
          <Card>
            {loading && !packages?.length ? (
              <Loader minHeight={400} />
            ) : packages?.length ? (
              <Table
                // scroll={{ x: 600 }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                showHeader={false}
              />
            ) : !loading ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Packages"
              />
            ) : null}
          </Card>
        </div>
      </Card>
    </>
  );
};

export default Packages;