import { createSlice } from '@reduxjs/toolkit';

const roomSlice = createSlice({
  name: 'room',
  initialState: {
    selectedRoom: [],
  },
  reducers: {
    setRoom(state, action) {
      state.selectedRoom = action.payload;
    },
  },
});

export const { setRoom } = roomSlice.actions;

export default roomSlice.reducer;
