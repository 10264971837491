import {
  WEBSITE_INTEGRATIONS,
  WEBSITE_INTEGRATIONS_DETAILS,
  END_USER_EVENT_CREATION,
  END_USER_EVENT_TYPES,
  TAX_CALCULATION_API,
  GET_DRAFT_DETAILS,
  END_USER_GUEST_COUNT
} from "./endpoints";
import client from "../axios";

export const generateCode = async (payload) => {
  return await client.post(WEBSITE_INTEGRATIONS, payload);
};

export const getIntigrationDetails = async (url) => {
  return await client.get(
    WEBSITE_INTEGRATIONS_DETAILS + `?generated_url=${url}`,
  );
};
export const getIntigrationEventType = async (url) => {
  return await client.get(
    END_USER_EVENT_TYPES +`?generated_url=${url}`,
  );
};

export const createEndUserEvent = async (payload) => {
  return await client.post(END_USER_EVENT_CREATION, payload);
};

export const checkGuestCount = async (payload) => {
  return await client.get(END_USER_GUEST_COUNT + payload + "/guests");
};

export const taxCalculationApi = async (payload) => {
  return await client.post(TAX_CALCULATION_API, payload);
};
export const deleteEventAsGuest = async (id) => {
  return await client.delete(END_USER_EVENT_CREATION + id + "/");
};

// export const draftData = async (id) => {
//   return await client.get(GET_DRAFT_DETAILS + `?location=${id}`);
// };

export const draftData = async (id) => {
  return await client.get(
    GET_DRAFT_DETAILS + `?location=${id}`,
  );
};