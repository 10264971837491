import React, { useEffect, useState } from 'react'
import { Typography, Card, Space, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

const StartGuide = ({ venueDetails, terms, servicesError, packagesError, pricingModel, roomDetails, profileImage, selectedVenueImage, stripeAccountStatus, enableServices, enablePackages, selectedServices, selectedPackages }) => {
    const { Title, Text } = Typography;

    const [checked, setChecked] = useState(false)

    return (

        <Card className="item-details" style={{ position: "fixed", width: "20%", height: "calc(70vh - 10px)", overflowY: "auto", }}>
            {/* <Card className="item-details" > */}
            <Space>
                <Title level={5}>Your Quickest Guide Chip</Title>
            </Space>
            <div className="events-details">
                <p
                    style={{
                        color: "#667085",
                        pointerEvents: "none",
                        fontSize: "12px",
                        marginTop: "15px",
                    }}
                >
                    Key steps to your account
                </p>
            </div>
            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {
                        venueDetails?.phone?.length <= 13 || !venueDetails?.email ? (
                            <div className="icon" style={{ background: "#FFCCCC" }}>
                                <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                            </div>
                        ) : (
                            <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                            </div>
                        )
                    }
                    <h6>Venue Details</h6>
                </div>
            </Card>
            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {
                        !roomDetails?.guestCount_0 || !roomDetails?.Price_0 ? (
                            <div className="icon" style={{ background: "#FFCCCC" }}>
                                <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                            </div>
                        ) : (
                            <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                            </div>
                        )
                    }
                    <h6>Room Details</h6>
                </div>
            </Card>

            {
                checked === false ? (
                    <>
                        <Card className="quickstart-card">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {
                                    !enableServices ? (
                                        <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                            <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                        </div>
                                    ) : (
                                        (servicesError || selectedServices?.length === 0) ? (
                                            <div className="icon" style={{ background: "#FFCCCC" }}>
                                                <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                                            </div>
                                        ) : (
                                            <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                            </div>
                                        )
                                    )
                                }
                                <h6>Services</h6>
                            </div>
                        </Card>
                        <Card className="quickstart-card">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                {
                                    !enablePackages ? (
                                        <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                            <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                        </div>
                                    ) : (
                                        (packagesError || selectedPackages.length === 0) ? (
                                            <div className="icon" style={{ background: "#FFCCCC" }}>
                                                <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                                            </div>
                                        ) : (
                                            <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                            </div>
                                        )
                                    )
                                }
                                <h6>Packages</h6>
                            </div>
                        </Card>
                    </>
                ) : (
                    <>
                        <Card className="quickstart-card">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                    <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                </div>
                                <h6>Services</h6>
                            </div>
                        </Card>
                        <Card className="quickstart-card">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                    <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                </div>
                                <h6>Packages</h6>
                            </div>
                        </Card>
                    </>
                )
            }

            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                        <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                    </div>
                    <h6>Event Calender</h6>
                </div>
            </Card>
            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {pricingModel?.packages === undefined || pricingModel?.services === undefined || pricingModel?.rooms === undefined ? (
                        <div className="icon" style={{ background: "#FFCCCC" }}>
                            <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                        </div>
                    ) : (
                        <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                            <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                        </div>
                    )}
                    <h6>Pricing Model</h6>
                </div>
            </Card>
            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                        <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                    </div>
                    <h6>Select Payment Terms</h6>
                </div>
            </Card>

            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {selectedVenueImage === null || profileImage === null ? (
                        <div className="icon" style={{ background: "#FFCCCC" }}>
                            <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                        </div>
                    ) : (
                        <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                            <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                        </div>
                    )}
                    <h6>Venue & Profile Logo</h6>
                </div>
            </Card>

            <Card className="quickstart-card">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {
                        !terms?.terms_html ? (
                            <div className="icon" style={{ background: "#FFCCCC" }}>
                                <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                            </div>
                        ) : (
                            <div className="icon" style={{ backgroundColor: "#D3F4EF" }}>
                                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                            </div>
                        )
                    }

                    <h6>Select Terms and Conditions</h6>
                </div>
            </Card>



            {stripeAccountStatus === false ? (
                <>
                    <Button className="mark-complete-button">
                        <Link to='/Integration/LinkStripe'>Link Stripe</Link>
                    </Button>
                    <Text style={{ marginTop: 14, display: "block", textAlign: "center" }} type="danger"> Please link the stripe account</Text>
                </>
            ) : (
                <Button className="mark-complete-button" onClick={() => setChecked(true)}>
                    Mark Complete
                </Button>
            )}

        </Card>

    )
}

export default StartGuide
