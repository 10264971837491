import React, { useState, useEffect, useMemo } from 'react'
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Input, Select, Tooltip } from "antd";
import Loader from "../../../common/Loader";
import { toast } from "react-hot-toast";
import { TagOutlined } from "@ant-design/icons";
import { getServices } from "../../../services/services";
import { serviceIcons } from "./utils";
// import { ReactComponent as PackagesIcon } from "../../../assets/icons/packages.svg";
import { PlusOutlined, DeleteOutlined, InfoOutlined } from "@ant-design/icons";

import "../EndUserStyles.scss";
import axios from "../../../network/axios";
import dummyPhoto from '../Assets/photo.png';
const { useBreakpoint } = Grid;

const Services = ({
  AllServices,
  selectedPackage,
  filteredServices,
  selected,
  setSelected,
  location,
  pricingModel
}) => {
  const { Title } = Typography;
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const screens = useBreakpoint();


  const dataSource = AllServices?.map((item, index) => ({
    id: item.id,
    key: index,
    name: item?.name,
    description: item.description,
    image: item?.images?.length ? item?.images[0]?.image : dummyPhoto,
    price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,

  }));

  const handleToggleDescription = (serviceId) => {
    setExpandedDescriptions((prev) => ({
        ...prev,
        [serviceId]: !prev[serviceId],
    }));
};
  const columns = useMemo(() => [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 40,
      render: (text) => (
        <img
          src={text}
          alt="Service"
          style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "3px" }}
        />
      ),
    },
    {
      title: '',
      width: "50%",
      key: 'service',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
          <h4 style={{ margin: 0 }}>{record.name}</h4>
                    <p style={{ margin: 0}}>
            {expandedDescriptions[record.key]
              ? record.description
              : `${record.description?.substring(0, 45)}${record.description?.length > 45 ? '...' : ''}`}
            {record.description?.length > 45 && (
              <span
                style={{ color: "#1890ff", cursor: "pointer", marginLeft: "5px" }}
                onClick={() => handleToggleDescription(record.key)}
              >
                {expandedDescriptions[record.key] ? 'Show Less' : 'Read More'}
              </span>
            )}
          </p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (text) => <h4 style={{ margin: 0 }}>${Number(text || 0).toFixed(2)}</h4>,
    },
    {
      title: 'Select',
      key: 'select',
      width: 40,
      render: (text, record) => (
        <Checkbox
          checked={selected.includes(record.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelected((prevSelected) => [...prevSelected, record]);
            } else {
              setSelected((prevSelected) =>
                prevSelected.filter((service) => service.id !== record.id)
              );
            }
          }}
        />
      ),
    }

  ]);

  return (

    <>
      <Card style={{ marginTop: screens.xs ? "10px" : "20px" }}>
        <Space
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>Services</Title>
          <Tooltip
            placement="top"
            title={`Pricing will be calculated as ${pricingModel === "PER_PERSON" ? "Per Person" : "Flat Price"}`}
          >
            <InfoOutlined
              className="add-input-field"
              style={{ marginBottom: '10px', cursor: 'pointer', marginLeft: '2px' }}
            />
          </Tooltip>
        </Space>

        <div
          className="events-details"
          style={{ marginTop: screens.xs ? "10px" : "0" }}
        >
          <p
            className="event-head"
            style={{ color: "#667085", pointerEvents: "none" }}
          >
            Select Services to add
          </p>
        </div>

        <div className="item-details" style={{ height: "140px" }}>
          <Card>
            {loading && !AllServices?.length ? (
              <Loader minHeight={400} />
            ) : AllServices?.length ? (
              <Table
                // scroll={{ x: 600 }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                showHeader={false}
              />
            ) : !loading ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Services"
              />
            ) : null}
          </Card>
        </div>
      </Card>
      {/* </div> */}
    </>
  );
};

export default Services;