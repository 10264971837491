import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Button, Grid, Upload, message, Row, Col, Tooltip } from 'antd';
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import axios from 'axios';
import demoUpload from '../Assets/squarebadge.png';
import usePopup from '../../../common/MessageModal/usePopup';
import ImageUploaders from "../../../components/ImageUploader";

import { ReactComponent as ImageIcon } from "../../../assets/icons/image.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/fill-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/trash.svg";
import { InfoOutlined } from "@ant-design/icons";

import styles from "../../../components/ImageUploader/imageUploader.module.scss";

const { useBreakpoint } = Grid;

const ImageUploader = ({ venue,selectedVenue, setSelectedVenueImage, setProfileImage, profileImage, selectedVenueImage, setErrors, error }) => {
  const { Title, Text } = Typography;
  const screens = useBreakpoint();
  const popup = usePopup();
  const [coverImages, setCoverImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const handleImageClick = (image) => {
    setSelectedVenueImage(image);
    localStorage.setItem('selectedVenueImage', JSON.stringify(image?.image));
  };

  useEffect(() => {
    if (coverImages?.length > 0) {
      const savedImage = JSON.parse(localStorage.getItem('selectedVenueImage'));
      if (savedImage && coverImages.some(img => img.image === savedImage.image)) {
        setSelectedVenueImage(savedImage);
        console.log(savedImage)
      } else {
        setSelectedVenueImage(coverImages[0]);
      }
    }
  }, [coverImages]);

  useEffect(() => {
    if (venue?.images?.length > 0) {
      setCoverImages(venue.images);
    }
    else(
      setCoverImages([])
    )
  }, [venue, selectedVenue]);

  useEffect(() => {
    if (profileImage) {
      setFiles(Array.isArray(profileImage) ? profileImage : [profileImage]);
    }
  }, [profileImage]);

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const getCompanyDetails = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_API_URL}/api/company/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('access_token')}`,
          },
        })
        .then((res) => {
          const data = res.data.results[0];
          setProfileImage(data?.logo);
        });
    } catch (error) {
      message.error('Failed to load company details');
    }
  };

  // single image uploading
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const beforeUpload = (file) => {
    const isLtMaxSize = file.size / 1024 / 1024 < 2;
    if (!isLtMaxSize) {
      message.error(
        `Can't upload "${truncateString(file.name, 30)}". File must be smaller than 2MB!`,
      );
    }

    return isLtMaxSize;
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".jpg, .jpeg, .png, .gif",
    fileList: files,
    showUploadList: false,
    beforeUpload,
    customRequest: dummyRequest,
    onChange(info) {
      if (info.file.status === "uploading") {
        setFiles(info.fileList);
        setProfileImage(info.fileList);
      } else if (info.file.status === "done") {
        setFiles(info.fileList);
        setProfileImage(info.fileList);
        message.success(
          `${truncateString(info.file.name, 30)} file uploaded successfully`,
        );
      } else if (info.file.status === "error") {
        message.error(
          `${truncateString(info.file.name, 30)} file upload failed.`,
        );
      } else if (info.file.status === "removed") {
        setProfileImage(info.fileList);
        setFiles(info.fileList);
        message.error(`${truncateString(info.file.name, 30)} file removed.`);
      }
    },
  };

  useEffect(() => {
    if (selectedVenueImage === null) {
      setErrors("Please Select any venue Image")
    }
    if (!coverImages.length) {
      setErrors("No Venue Images Found")
    }
    if (profileImage === null) {
      setErrors("Please Upload Company Logo")
    }
  }, [selectedVenueImage, profileImage])

  return (
    <div>
      <Card style={{ marginTop: '20px', borderColor: error && "#ff4d4f" }}>
        <Space style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Title level={4}>
            Venue & Profile Logo
            &nbsp;
            {
              !screens.xs ? (
                <Tooltip placement="top" title={"If there are no venues images then go back to edit venue and add images, only uploaded venue images will appear here"}>
                  <InfoOutlined
                    className='add-input-field'
                    style={{ marginBottom: '10px', cursor: 'pointer' }}
                  />
                </Tooltip>
              ) : null
            }

          </Title>
          {screens.xs ?
            selectedVenueImage === null || profileImage === null ? (
              <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#FFCCCC" }}>
                <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
              </div>
            ) : (
              <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
                <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
              </div>
            ) : null
          }
        </Space>

        {error ? (
          <Text style={{ display: "block" }} type="danger">
            {error}
          </Text>
        ) : null}

        <div>
          <p
            className="event-head"
            style={{
              color: '#667085',
              pointerEvents: 'none',
            }}
          >
            Logo
          </p>
        </div>

        <div className={styles.imageUploader}>
          {files?.length ? (
            files.map((file, i) => (
              <div key={i} className={styles["uploaded-image-wrapper"]}>
                <div className={styles.actions}>
                  <Upload {...props} showUploadList={false}>
                    <Button
                      type="primary"
                      size="large"
                      shape="circle"
                      icon={<EditIcon />}
                    />
                  </Upload>
                  <Button
                    type="primary"
                    size="large"
                    icon={<DeleteIcon />}
                    shape="circle"
                    danger
                    onClick={() => {
                      popup.confirm(
                        "Delete Image",
                        "Are you sure? This action is permanent and cannot be undone.",
                        () => {
                          setFiles(null);
                          setProfileImage(null);
                        },
                        true,
                      );
                    }}
                  />
                </div>
                <img
                  src={
                    file?.originFileObj
                      ? URL.createObjectURL(file.originFileObj)
                      : file
                  }
                  alt={file.name}
                />
              </div>
            ))
          ) : (
            <Upload {...props} style={{ width: "100%" }}>
              <div
                className={`${styles.addImage} ${styles.oneImage}`}
              >
                <img
                  style={{ maxWidth: "100%" }}
                  src={demoUpload}
                  alt="Upload"
                />
                <p>Click to add Logo for your Company</p>
                <Button
                  className="button-terms"
                  size="large"
                  block
                  style={{
                    width: "150px",
                    marginTop: "0px",
                    padding: "0px 15px"
                  }}
                  loading={loading}
                >
                  Add Logo
                </Button>
              </div>
            </Upload>
          )}
        </div>

        <div>
          <p
            className="event-head"
            style={{
              color: '#667085',
              pointerEvents: 'none',
              marginTop: '15px',
            }}
          >
            Cover Photos
          </p>
        </div>

        {/* updates of the UI */}

        <div className='image-selectors'>
          <div style={{ width: "100%", height: "100%", textAlign: "center", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
            <Row gutter={[24, 24]} style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
              {coverImages.length > 0 ? (
                coverImages.map((image, index) => (
                  <Col span={coverImages.length === 1 ? 24 : 12} key={image.image_name}> {/* Adjust the span when only one image */}
                    <div
                      className={`image-wrapper-m ${selectedVenueImage === image ? 'selected-item' : ''}`}
                      onClick={() => handleImageClick(image)}
                      style={{ display: 'flex', justifyContent: 'center' }} // Ensure the image is centered
                    >

                      <img
                        className="image-selector-single"
                        src={image.image}
                        alt={image.image_name}
                      />
                      <div className="overlay">
                        <span className="tick-icon">✔</span>
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto", height: screens.xs ? "250px" : "calc(100vh - 250px)" }}>
                  <div>
                    <img
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        margin: '0 auto',
                        marginBottom: "5px"
                      }}
                      src={demoUpload}
                      alt="Upload"
                    />
                    <p>Your Uploaded Venue Images will appear here</p>
                  </div>
                </div>
              )}
            </Row>
          </div>

          {coverImages.length > 0 ? (
            <p
              style={{
                color: "#858D9D",
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Please Select an image to upload it
            </p>
          ) : null}
        </div>


      </Card>
    </div>
  );
}

export default ImageUploader;