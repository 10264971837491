import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Checkbox, Grid, Empty, Table, Switch, Popover, Button } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getPackages } from "../../../services/packages";
import Loader from "../../../common/Loader";

import infoImage from '../../../assets/info-red.png'
import templateImage from "../../../assets/background.jpg";

const UserPackages = ({ draftDetails, error, setErrors, venue, selectedPackages, setSelectedPackages, enablePackages, setEnablePackages, pricingModel }) => {
    const { useBreakpoint } = Grid;
    const { Title, Text } = Typography;
    const screens = useBreakpoint();

    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const filteredPackages = packages.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [localEnablePackages, setLocalEnablePackages] = useState(() => {
        const savedState = localStorage.getItem('enablePackages');
        return savedState !== null ? JSON.parse(savedState) : true;
    });

       // Sync `enablePackages` state between parent and local storage
       useEffect(() => {
        setEnablePackages(localEnablePackages);
    }, [localEnablePackages]);

    const handleEnablePackagesChange = (checked) => {
        setLocalEnablePackages(checked);
        localStorage.setItem('enablePackages', JSON.stringify(checked));
    };

    const fetchPackages = async () => {
        if (!venue?.id) return;
        setLoading(true);
        try {
            const res = await getPackages({
                page: 1,
                location: venue?.id,
            });
            const fetchedPackages = res.data.results;
            if (draftDetails && draftDetails.length > 0) {
                const draftPackageIds = draftDetails.map(pkg => pkg.id);
                setSelectedPackages(draftPackageIds);
            } else {
                const savedDraft = JSON.parse(localStorage.getItem('savedDraftPackages'));
                if (savedDraft && savedDraft.length > 0) {
                    setSelectedPackages(savedDraft);
                } else {
                    setSelectedPackages(fetchedPackages.map(pkg => pkg.id));
                }
            }


            setPackages(fetchedPackages);
        } catch (error) {
            const errorCode = error?.response?.status || 500;
            if (errorCode === 500 || errorCode === 503) {
                window.location.href = "/server-error";
                console.log("Redirecting to server error page...");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (venue?.id) {
            fetchPackages();
        }
    }, [venue, draftDetails]);

    useEffect(() => {
        if (venue?.id) {
            fetchPackages();
        }
    }, [pricingModel]);

    const handleCheckboxChange = (item) => {
        const isSelected = selectedPackages.includes(item.id);
        const updatedPackages = isSelected
            ? selectedPackages.filter(id => id !== item.id)
            : [...selectedPackages, item.id];

        setSelectedPackages(updatedPackages);
        localStorage.setItem('savedDraftPackages', JSON.stringify(updatedPackages));

        if (updatedPackages.length === 0) {
            setErrors(prev => ({ ...prev, packages: "Please select at least one package." }));
        } else {
            setErrors(prev => {
                const { packages, ...rest } = prev;
                return rest;
            });
        }
    };

    const handleToggleDescription = (packageID) => {
        setExpandedDescriptions((prev) => ({
            ...prev,
            [packageID]: !prev[packageID],
        }));
    };

    const dataSource = filteredPackages.map((item, index) => ({
        key: index,
        name: item.name,
        description: item.description,
        image: item.images?.length ? item.images[0].image : templateImage,
        total_price: item.total_price,
        price: pricingModel === "PER_PERSON" ? `${item?.pp_price || "0"}` : `${item?.flat_price || "0"}`,
        id: item.id
    }));

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 40,
            render: (text) => (
                <img
                    src={text}
                    alt="Service"
                    style={{ width: "50px", height: "50px", borderRadius: "5px", opacity: enablePackages ? '' : '0.4' }}
                />
            ),
        },
        {
            title: 'Service',
            key: 'service',
            width: "50%",
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <h4 style={{ margin: 0, color: enablePackages ? 'black' : '#999' }}>{record.name}</h4>
                    <p style={{ margin: 0, color: enablePackages ? 'black' : '#999' }}>
                        {expandedDescriptions[record.key]
                            ? record.description
                            : `${record.description?.substring(0, 45)}${record.description?.length > 45 ? '...' : ''}`}
                        {record.description?.length > 45 && (
                            <span
                                style={{ color: "#1890ff", cursor: "pointer", marginLeft: "5px" }}
                                onClick={() => handleToggleDescription(record.key)}
                            >
                                {expandedDescriptions[record.key] ? 'Show Less' : 'Read More'}
                            </span>
                        )}
                    </p>
                </div>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => <h4 style={{ margin: 0, color: enablePackages ? 'black' : '#999' }}>${text || 0}</h4>,
        },
        {
            title: 'Select',
            key: 'select',
            width: 40,
            render: (text, record) => (
                <Checkbox
                    checked={selectedPackages.includes(record.id)}
                    onChange={() => handleCheckboxChange({ id: record.id })}
                    disabled={!enablePackages}
                />
            ),
        },
    ];

    const content = (
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <img src={infoImage} alt="info image" />&nbsp;&nbsp;
          <div>
            <p>Let's create a package first then come back here!</p>
            <Button size="small"><Link to='/company/packages'>Create Package</Link></Button>
          </div>
        </div>
      );

    return (
        <div>
            <Card style={{
                  marginTop: screens.xs ? "120px" : "20px",
                backgroundColor: enablePackages ? "white" : "#f0f0f0", // Disabled background
                color: enablePackages ? "black" : "#999", // Disabled text color
            }}>
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Title level={4} style={{ color: enablePackages ? "black" : "#999" }}>Packages</Title>&nbsp;&nbsp;
                    {
                            screens.xs ?
                            error || !enablePackages || selectedPackages.length === 0 ? (
                                    <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#FFCCCC" }}>
                                        <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                                    </div>
                                ) : (
                                    <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
                                        <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                                    </div>
                                ) : null
                        }
                    </div>

                    <Switch
                        style={{ marginRight: "10px" }}
                        checked={localEnablePackages}
                        onChange={handleEnablePackagesChange}
                    />
                </Space>

                {/* {error ? (
                    <Text style={{ display: "block" }} type="danger">
                        {error}
                    </Text>
                ) : null} */}
                <div
                    className="events-details"
                    style={{ marginTop: screens.xs ? "10px" : "0" }}
                >
                    <p
                        className="event-head"
                        style={{ color: enablePackages ? "#667085" : "#999", pointerEvents: "none" }}
                    >
                        Select Packages to add
                    </p>
                </div>
                <div className="packages-services-card" style={{ height: "140px" }}>
                    <Card>
                        {loading && !filteredPackages?.length ? (
                            <Loader minHeight={100} />
                        ) : filteredPackages?.length ? (
                            <Table
                                // scroll={{ x: 600 }}
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                showHeader={false}
                                rowKey="key"
                                style={{ borderCollapse: 'separate', borderSpacing: '0' }}
                            />
                        ) : !loading ? (
                            <>
                            { enablePackages === true ? (
                                 <Popover content={content} placement="topLeft" title={null} trigger="hover">
                                <Text type="danger">It seems like there are no active packages. Please select at least one package</Text>
                                </Popover>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Packages"/>
                            )
                        }
                            </>
                        ) : null}
                    </Card>
                </div>
            </Card>
        </div>
    );
};

export default UserPackages;