import React, { useState } from "react";
import "./PackageCard.scss";
import { ReactComponent as DurationIcon } from "../../../assets/duration.svg";
import { ReactComponent as EditIcon } from "../../../assets/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/deleteIcon.svg";
import { ReactComponent as PackageLength } from "../../../assets/pacakgeLength.svg";
import cardImgN from "../../../assets/background.jpg";
import ConfirmationModal from "../Modals/ConfirmationModal";
import EditPackageForm from "../Modals/EditPackageForm";
import axiosInstance from "../../../common/axiosInstance";
import moment from "moment/moment";
import { Switch } from "antd";

const PackageCard = ({ packageData, getPackages, activeTab }) => {
  const onChange = (checked) => {
    if (checked) {
      updatePackage("activated");
    } else {
      updatePackage("deactivated");
    }
  };
  const [deleteServiceModal, setDeleteServiceModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deletePackage = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.delete(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/package/${packageData?.id}/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      );
      getPackages();
      setDeleteServiceModal(false);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = "/server-error";
        console.log("Redirecting to server error page...");
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const updatePackage = async (status) => {
    setIsLoading(true);
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_BASE_API_URL}/api/company/package/${packageData?.id}/`,
        {
          status: status,
        },
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("access_token")}`,
          },
        },
      );
      if (activeTab === "All") {
        getPackages();
      } else if (activeTab == "Active") {
        getPackages("activated");
      } else if (activeTab === "Inactive") {
        getPackages("deactivated");
      } else {
        getPackages("draft");
      }
      setIsEditModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="package-card-wrapper">
        <div className="package-header">
          <div className="package-name">{packageData?.name}</div>
          <div className="package-price">${packageData?.total_price}</div>
        </div>
        <div className="package-img">
          <div className="btns">
            <span
              className="btn"
              onClick={() => {
                setIsEditModalOpen(true);
              }}
            >
              <EditIcon />
            </span>
            <span
              className="btn"
              onClick={() => {
                setDeleteServiceModal(true);
              }}
            >
              <DeleteIcon />
            </span>
          </div>
          <img
            src={
              packageData?.images?.length
                ? packageData?.images[0]?.image
                : cardImgN
            }
            alt="card-pic"
          />
        </div>
        <div className="package-description">
          <p
            className="line-clamp"
            dangerouslySetInnerHTML={{
              __html: packageData?.description,
            }}
          ></p>
        </div>
        <div className="package-details">
          <div className="info-wrapper">
            <div className="icon-text">
              <span className="icon">
                <DurationIcon />
              </span>
              &nbsp;
              <span>Package Length</span>
            </div>
            <div className="value">
              This package is ONLY available from{" "}
              <span>{moment(packageData?.start_date).format("ll")}</span> to{" "}
              <span>{moment(packageData?.end_date).format("ll")}</span>
            </div>
          </div>
          <div className="info-wrapper">
            <div className="icon-text">
              <span className="icon">
                <PackageLength />
              </span>
              &nbsp;
              <span>Package Duration</span>
            </div>
            <div className="value">
              This package duration is{" "}
              <span> up to {packageData?.duration_minutes} hours </span>{" "}
            </div>
          </div>
        </div>
        <div className="package-footer">
          <div>
            <Switch
              defaultChecked={
                packageData?.status === "activated" ? true : false
              }
              onChange={onChange}
              className="package-switch"
              size="small"
            />{" "}
            <span>
              {packageData?.status === "activated" ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={deleteServiceModal}
        setOpen={setDeleteServiceModal}
        isLoading={isLoading}
        onConfirm={deletePackage}
        content={`Are you sure you want to delete ${packageData?.name}?`}
      />
      <EditPackageForm
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        getPackages={getPackages}
        packageData={packageData}
      />
    </>
  );
};

export default PackageCard;
