import axios from "axios";
import { useNavigate } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASE_API_URL || "";
const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorCode = error?.response?.status || 500;
    if (errorCode >= 500 && errorCode <= 503) {
      window.location.href = "/server-error";
    } else {
      console.error("API Error:", error);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
