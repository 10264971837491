import { React, useState, useEffect } from 'react';
import { Typography, Card, Form, Input, Row, Col, DatePicker, TimePicker, Grid, Select, InputNumber } from 'antd';
import dayjs from "dayjs";
import "../EndUserStyles.scss";

const { useBreakpoint } = Grid;
const { Option } = Select;

const EventInfo = ({ selectedRooms, venueInfo, eventType, setSelectedEvent, setEventStartTime, setEventEndTime, setEventGuestCount, setEventDate, }) => {
  const screens = useBreakpoint();
  const [month, setMonth] = useState(dayjs().format("YYYY-MM"));
  const [days, setDays] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]); // State for blocked dates
  const [startIndex, setStartIndex] = useState(0);
  const [error, setError] = useState([])
  const visibleDays = 5;
  const endIndex = startIndex + visibleDays;
  const [dateDuration, setDateDuration] = useState({
    duration_minutes: "",
    event_date: "",
  });

  const [form] = Form.useForm();

  const { Title } = Typography;

  useEffect(() => {
    const selectedMonth = dayjs(month);
    const daysInMonth = selectedMonth.daysInMonth();
    const daysArray = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const date = selectedMonth.date(day);
      const dayObject = {
        day: date.format("DD"),
        day_name: date.format("ddd"),
        full_date: date.format("YYYY-MM-DD"),
      };
      daysArray.push(dayObject);
    }
    setDays(daysArray);
  }, [month]);

  useEffect(() => {
    form.validateFields(['num_of_guests']);
  }, [selectedRooms, form]);

  useEffect(() => {
    if (venueInfo && venueInfo?.web_calendar_view && venueInfo?.web_calendar_view.days) {
      setBlockedDates(venueInfo?.web_calendar_view.days);
    }
  }, [venueInfo]);

  const checkStartTime = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Start time is required"));
    }
    const formattedTime = value ? value.format("HH:mm") : "";
    setEventStartTime(formattedTime);
    return Promise.resolve();
  };

  const checkEndTime = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("End time is required"));
    }
    const formattedTime = value ? value.format("HH:mm") : "";
    setEventEndTime(formattedTime);
    return Promise.resolve();
  };
  const handleDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    setDateDuration((prev) => ({
      ...prev,
      event_date: formattedDate,
    }));
    setEventDate(formattedDate);
  };

  const validateGuestCount = (value) => {
    if (value === undefined || value === "") {
      return "Please enter the number of guests!";
    }
    if (Number(value) <= 0) {
      return "Please enter a valid guests number!";
    } else if (selectedRooms.length && Number(value) > selectedRooms[0]?.max_guests) {
      return `Max ${selectedRooms[0]?.max_guests} guests are allowed!`;
    }
    return null;
  };

  const handleFormChange = (changedValues, allValues) => {
    console.log(changedValues,allValues,"form changes ")
    if ("num_of_guests" in changedValues) {
      const errorMessage = validateGuestCount(changedValues.num_of_guests);
      if (errorMessage) {
        form.setFields([
          {
            name: "num_of_guests",
            errors: [errorMessage],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "num_of_guests",
            errors: [],
          },
        ]);
      }
    }
    setEventGuestCount(allValues.num_of_guests);
  };



  return (
    <Card className="booking-card">
      <Title level={4}>Event information</Title>
      <Form form={form} name="event-info" onValuesChange={handleFormChange}>
        <Row gutter={[16, 40]}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="event_type"
              label={
                <span>
                  Hosting Event <span style={{ color: "red" }}>*</span>
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Select
                size="large"
                placeholder="Select event type"
                style={{ width: '100%' }}
                onChange={(value) => setSelectedEvent(value)}
              >
                {eventType?.map((item, key) => (
                  <Option value={item.id} key={key}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="event_date"
              label={
                <span>
                  Event Date <span style={{ color: "red" }}>*</span>
                </span>
              }
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Event date is required!' }]}
            >
              <DatePicker
                size="large"
                style={{ width: '100%' }}
                disabledDate={(current) =>
                  blockedDates.includes(current.format('YYYY-MM-DD')) || current.isBefore(dayjs().startOf('day'))
                }
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="num_of_guests"
              label={
                <span>
                  Guest Count <span style={{ color: "red" }}>*</span>
                </span>
              }              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            
            >
              <Input
                size="large"
                placeholder="Number of guests"
                maxLength={4}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>


          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="start_time"
              label={
                <span>
                  Start Time <span style={{ color: "red" }}>*</span>
                </span>
              }              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ validator: checkStartTime }]}
            >
              <TimePicker
                size="large"
                showNow={false}
                style={{ width: '100%' }}
                format="hh:mm A"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Form.Item
              name="end_time"
              label={
                <span>
                  End Time <span style={{ color: "red" }}>*</span>
                </span>
              }              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ validator: checkEndTime }]}
            >
              <TimePicker
                size="large"
                showNow={false}
                style={{ width: '100%' }}
                format="hh:mm A"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default EventInfo;
