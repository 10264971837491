import React, { useState, useEffect } from "react";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import usePopup from "../../../../../common/MessageModal/usePopup";
import {
  createCheckoutSession,
  madePayment,
  getPaymentMethods,
} from "../../../../../services/events";

// UI's
import { Button, Modal, Spin } from "antd";
import { Form, Input } from "antd";

import { ReactComponent as CardIcon } from "../../../../../assets/icons/credit-card.svg";
import { ReactComponent as CashIcon } from "../../../../../assets/icons/cash.svg";


import AddCard from "./AddCard";
import StripeAccountError from "./StripeAccountError";
import toast from "react-hot-toast";

const PaymentMode = ({
  open,
  handelClose,
  payment,
  setPayButtonLoading,
  fetchAllPayments,
  event,
  stripePromise,
  deleteCreditCard,
  accountID
}) => {
  const popup = usePopup();
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState("credit");
  const [chequeImage, setChequeImage] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);

  const [cards, setCards] = useState([]);
  const [checkCreditCardinfo, setCheckCreditinfo] = useState(true);


  const handleCancel = () => {
    handelClose();
    form.resetFields();
    setChequeImage(null);
    setPaymentType("credit");
  };

  const madePaymentOnServer = async (data) => {
    console.log(data.notes);

    let formData = new FormData();
    if (paymentType === "cheque" && chequeImage.length > 0) {
      for (let i = 0; i < chequeImage.length; i++) {
        formData.append("files", chequeImage[i].originFileObj);
      }
    }
    formData.append("payment_id", payment?.id);
    formData.append("type", paymentType);
    formData.append("paid_by_email", event?.contacts[0]?.email);
    formData.append("notes", data?.notes || "");
    setLoading(true);
    try {
      const res = await madePayment(formData);
      popup.success("Success!", res.data.message);
      fetchAllPayments();
      handelClose();
      form.resetFields();
    } catch (error) {
      popup.error("Opss!", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const createSession = async () => {
    await createCheckoutSession({ payment_id: payment?.id }).then((response) => {
      setClientSecret(response.data.data.client_secret);
      setOpenCheckout(true);
    }).catch(error => {
      toast.error(error?.response?.data?.message);
      setOpenCheckout(false);
    });
  };



  const getPaymentMethodsList = async () => {
    try {
      const res = await getPaymentMethods(event.id);
      setCards(res?.data?.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setCheckCreditinfo(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = {
      paymentid: payment.id,
      notes: values.notes,
    };
    if (
      (paymentType == "credit" && cards.length > 0) ||
      paymentType == "other"
    ) {
      await madePaymentOnServer(data).then(() => {
        setLoading(false);
        return;
      });
    } else {
      await createSession();
    }
    setLoading(false);
  };

  const renderdButtons = () => {
    let buttons = [
      <Button size={"large"} key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button
        style={{ marginLeft: "auto" }}
        size={"large"}
        key="next"
        type="primary"
        onClick={() => form.submit()}
        loading={loading}
        disabled={paymentType == "credit" && !accountID}
      >
        Pay
      </Button>,
    ];
    return buttons;
  };
  const handleAddCard = async () => {
    try {
      await getPaymentMethodsList();
      // fetchAllPayments();
      getPaymentMethodsList();
    } catch (error) {
      console.error("Error adding card:", error);
    }
  };
  const payment_types = [
    {
      label: "Credit / Debit",
      value: "credit",
      icon: <CardIcon />,
    },
    {
      label: "Other Methods",
      value: "other",
      icon: <CashIcon />,
    },
  ];

  return (
    <>
      {open && (
        <Modal
          centered
          title={"Add payment method"}
          open={open}
          onCancel={handleCancel}
          footer={
            // paymentType == "credit" && cards.length == 0 ? "" :
            renderdButtons()
          }
        >
          <Form
            style={{ marginTop: 24 }}
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
            className="modal-form payments-modal"
            onFinish={handleSubmit}
          >
            <div className="options">
              {payment_types.map((item, i) => (
                <div
                  key={i}
                  className={`item ${item.value === paymentType && "active"}`}
                  onClick={() => setPaymentType(item.value)}
                >
                  {item.icon}
                  {item.label}
                </div>
              ))}
            </div>
            {paymentType === "credit" && !accountID ? (
                <StripeAccountError />
            
            ) : null}
            {paymentType === "other" ? (
              <>
                <Form.Item label="Name">
                  <Input
                    defaultValue={payment?.created_user}
                    size="large"
                    placeholder="Name is missing"
                  />
                </Form.Item>
                <Form.Item label="Amount $">
                  <Input
                    prefix={<>$</>}
                    value={payment?.inst_payment_subtotal}
                    size="large"
                    readOnly
                    placeholder="Amount missing"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      {" "}
                      Note <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  name="notes"
                  rules={[
                    {
                      required: true,
                      message: "Please add Notes!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Instructions..." />
                </Form.Item>
              </>
            ) : null}
          </Form>
        </Modal>
      )}
      {(openCheckout && accountID) && (
        <Modal
          centered
          // title={"Payment"}
          open={true}
          onCancel={() => setOpenCheckout(false)}
          footer={[]}
        >
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              onComplete: () => madePaymentOnServer(payment?.id),
            }}
          >
            <EmbeddedCheckout id="card-element" />
          </EmbeddedCheckoutProvider>
        </Modal>
      )}

      
    </>
  );
};

export default PaymentMode;
