import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Row, Col, Form, Input, Grid, Button, Popover, Title } from 'antd';
import InputMask from 'react-input-mask';
import { DeleteOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { getVenue } from '../../../services/venues';
import Loader from "../../../common/Loader";
import infoImage from '../../../assets/info-red.png'
import { Link } from 'react-router-dom';
const { useBreakpoint } = Grid;

const VenueDetails = ({ draftDetails, setVenueDetails, venueDetails, error, setErrors, venue, setVenue, selectedVenue, venueMissingModal }) => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(false);

  console.log("draftDetails", draftDetails)

  const fetchVenue = async () => {
    setLoading(true);
    try {
      const res = await getVenue(selectedVenue?.id);
      setVenue(res?.data);
    } catch (error) {
      const errorCode = error?.response?.status || 500;
      if (errorCode === 500 || errorCode === 503) {
        window.location.href = '/server-error';
        console.log('Redirecting to server error page...');
      } else {
        // toast.error('Oops...', error?.response?.data?.detail);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedVenue?.id) {
      fetchVenue();
    }
  }, [selectedVenue]);

  useEffect(() => {
    // Set form values when draftDetails changes
    if (draftDetails && Object.keys(draftDetails).length > 0) {
      let phone = '';
      let email = '';
      if (draftDetails?.location_contact) {
        phone = draftDetails?.location_contact?.phone_number ? `(${draftDetails.location_contact.phone_number.slice(0, 3)}) ${draftDetails.location_contact.phone_number.slice(3, 6)}-${draftDetails.location_contact.phone_number.slice(6)}` : '';
        email = draftDetails?.location_contact?.email || '';
      } else {
        phone = draftDetails?.location?.phone ? `(${draftDetails.location.phone.slice(0, 3)}) ${draftDetails.location.phone.slice(3, 6)}-${draftDetails.location.phone.slice(6)}` : '';
        email = draftDetails?.location?.email || '';
      }
      const aboutUsText = draftDetails?.about_us_text || '';
  
      form.setFieldsValue({
        phone,
        email,
        about_us_text: aboutUsText,
      });
  
      // Set venue details in the parent component
      setVenueDetails({ phone, email, about_us_text: aboutUsText });
    } else {
      console.log('no', venue.phone) // Fallback if draftDetails is not available
      const phone = venue.phone ? `(${venue.phone.slice(0, 3)}) ${venue.phone.slice(3, 6)}-${venue.phone.slice(6)}` : '';
      const email = venue.email || '';
      const aboutUsText = venue.about_us_text || '';
  
      form.setFieldsValue({
        phone,
        email,
        about_us_text: aboutUsText,
      });
  
      // Update the venue details state in parent
      setVenueDetails({ phone, email, about_us_text: aboutUsText });
    }
  }, [draftDetails, venue]);


  const deleteData = () => {
    form.setFieldsValue({
      phone: '',
      email: '',
    });
  };

  const deleteDescription = () => {
    form.setFieldsValue({
      about_us_text: '',
    });
  };

  const handleFormChange = (changedValues, allValues) => {
    const phoneNumber = allValues.phone;
    const email = allValues.email;
    if (!email || phoneNumber?.length <= 13) {
      setErrors(prev => ({ ...prev, venue: "Please fill in the required fields *" }));
    }
    else {
      setErrors(null);
    }
    setVenueDetails(allValues);
  };

  const content = (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <img src={infoImage} alt="info image" />&nbsp;&nbsp;
      <div>
        <p>No venue?<br />Why don't we create a venue first then come back here!</p>
        <Button size="small"><Link to='/company/venues'>Create Venue</Link></Button>
      </div>
    </div>
  );

  return (
    <div>
      <Card style={{ borderColor: error && "#ff4d4f", marginTop: screens.xs ? "120px" : "92px" }}>

        {
          loading ? (
            <Loader minHeight={200} />
          ) : (
            <>
              <Space style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                <Title level={4}>Venue Details</Title>
                {
                  screens.xs ?

                    venueDetails?.phone?.length <= 13 || !venueDetails?.email ? (
                      <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#FFCCCC" }}>
                        <CloseOutlined style={{ fontSize: "8px", color: "#F6002C" }} />
                      </div>
                    ) : (
                      <div className="icon" style={{ marginBottom: "10px", backgroundColor: "#D3F4EF" }}>
                        <CheckOutlined style={{ fontSize: "8px", color: "#116557" }} />
                      </div>
                    )
                    : null
                }

              </Space>

              {error ? (
                <Text style={{ display: "block" }} type="danger">
                  {error}
                </Text>
              ) : null}

              {venue?.length === 0 && venueMissingModal === false ? (
                <Popover content={content} placement="topLeft" title={null} trigger="hover">
                  <Text style={{ display: "block" }} type="danger">
                    No venue is found, please go to the venue menu option under company setting and create a venue.
                  </Text>
                </Popover>
              ) : null}

              <Form
                form={form}
                name="venueDetailsForm"
                layout="vertical"
                onValuesChange={handleFormChange} // This will trigger on any form field change
              >
                <Row gutter={[8, 0]} align="middle">
                  <Col span={screens.xs ? 24 : 11}>
                  <Title style={{ fontSize: "16px" }}>Phone<span style={{ color: 'red' }}>*</span></Title>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: 'Phone number is required!',
                        },
                        {
                          pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                          message: 'Please enter a valid phone!',
                        },
                      ]}
                      validateStatus={error?.phone ? 'error' : ''}
                      help={error?.phone}
                    >
                      <InputMask mask="(999) 999-9999" maskChar="">
                        {() => (
                          <Input
                            maxLength={50}
                            size="large"
                            placeholder="Type Phone here..."
                            type="text"
                          />
                        )}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col span={screens.xs ? 24 : 11}>
                  <Title style={{ fontSize: "16px" }}>Email<span style={{ color: 'red' }}>*</span></Title>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Email is required!',
                        },
                      ]}
                      validateStatus={error?.email ? 'error' : ''}
                      help={error?.email}
                    >
                      <Input
                        maxLength={50}
                        size="large"
                        placeholder="Enter Email..."
                      />
                    </Form.Item>
                  </Col>
                  <Col span={screens.xs ? 20 : 0}></Col>
                  <Col span={2}>
                    <Button className="delete-item" onClick={deleteData}>
                      <DeleteOutlined style={{ color: '#EB3D4D' }} />
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[8, 0]} align="middle">
                  <Col span={screens.xs ? 24 : 11}>
                  <Title style={{ fontSize: "16px" }}>About us</Title>
                    <Form.Item
                      name="about_us_text"
                    >
                      <TextArea
                        size="large"
                        placeholder="Type description here..."
                        rows={screens.xs ? 4 : 1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={screens.xs ? 20 : 11}></Col>
                  <Col span={2}>
                    <Button className="delete-item" onClick={deleteDescription}>
                      <DeleteOutlined style={{ color: '#EB3D4D' }} />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )
        }

      </Card>
    </div>
  );
};

export default VenueDetails;