import { Modal } from "antd";
import React, { useState, useEffect } from "react";
import "./EditLocation.scss";

import { ReactComponent as CrossIcon } from "../../../../assets/Modals/CrossIcon.svg";
import { ReactComponent as BackArrow } from "../../../../assets/Modals/backArrow.svg";
import { Input, Row, Col } from "antd";
import AddNewRoom from "../AddNewRoom";
import AddImage from "../AddImage";
import ImageUploader from "../../../../components/ImageUploader";
import axios from "axios";
import { Spin } from "antd";
import PhoneMask from "../../../../common/PhoneMask";
import ZipCodeMask from "../../../../common/ZipCodeMask";
import MessageModal from "../MessageModal";

const options = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const EditLocation = ({
  open,
  setOpen,
  getVenues,
  venueData,
}) => {
  const [isfirstScreen, setIsFirstScreen] = useState(true);
  // eslint-disable-next-line
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [isAddNewRoom, setIsAddNewRoom] = useState(false);
  const [isAddImage, setIsAddImage] = useState(false);
  const [locationName, setLocationName] = useState(venueData?.name);
  const [locationAddress, setLocationAddress] = useState(
    venueData?.address_line1,
  );
  const [locationCity, setLocationCity] = useState(venueData?.zip_code?.city);
  const [locationState, setLocationState] = useState(
    venueData?.zip_code?.state,
  );
  const [isSuccessModalOpen, setIsSuccessModelOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [locationZipCode, setLocationZipCode] = useState(
    venueData?.zip_code?.zip_code,
  );
  const [phone, setPhone] = useState(venueData?.phone);
  const [email, setEmail] = useState(venueData?.email);
  const [isSpin, setIsSpin] = useState(false);
  const [file, setFile] = useState(null);

  const [isEmailError, setEmailError] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState(venueData?.images);

  useEffect(() => {
    if (isSuccessModalOpen) {
      const timer = setTimeout(() => {
        setIsSuccessModelOpen(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isSuccessModalOpen]);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const updateLocation = () => {
    try {
      setIsSpin(true);
      const formData = new FormData();
      if (file) {
        formData.append("image", file);
      }
      formData.append("name", locationName);
      formData.append("address_line1", locationAddress);
      formData.append("city", locationCity);
      formData.append("state", locationState);
      formData.append("zip_code", locationZipCode);
      formData.append("phone", phone);
      formData.append("email", email);
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i].originFileObj);
        }
      }
      axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/api/company/location/${venueData?.id}/`,
          formData,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("access_token")}`,
            },
          },
        )
        .then((res) => {
          setIsSuccessModelOpen(true);
          setOpen(false);
          setLocationName("");
          setLocationAddress("");
          setLocationCity("");
          setLocationState("");
          setLocationZipCode("");
          setPhone("");
          setEmail("");
          setIsSpin(false);
          getVenues();
          setImages([]);
        });
    } catch (error) {
      setIsSpin(false);
    }
  };

  return (
    <>
      <Modal
        title={null}
        centered
        open={open}
        onOk={() => setOpen(false)}
        footer={null}
        className="add-new-location"
        closable={false}
        onCancel={() => {
          setOpen(false);
          setEmailError(false);
          setImages([]);
        }}
      >
        <div className="header">
          <div className="header-left">
            <BackArrow
              onClick={() => {
                if (isfirstScreen) {
                  setOpen(false);
                } else {
                  setIsFirstScreen(true);
                  setIsSecondScreen(false);
                }
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="header-center">Edit Venue</div>
          <div className="header-right">
            <CrossIcon
              onClick={() => {
                setEmailError(false);
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        {isfirstScreen && (
          <Spin spinning={isSpin} delay={500}>
            <div className="content-edit-company-2">
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter Location Name"
                  size="large"
                  value={locationName}
                  onChange={(e) => {
                    setLocationName(e.target.value);
                  }}
                />

                {isSubmit && !locationName && (
                  <span style={{ color: "red" }}>
                    Please enter location name
                  </span>
                )}
              </div>
              <div className="content-edit-company-input-wrapper">
                <Input
                  placeholder="Enter Address"
                  size="large"
                  value={locationAddress}
                  onChange={(e) => setLocationAddress(e.target.value)}
                />
              </div>

              <Row gutter={[16, 16]}>
                <Col md={8}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Enter City"
                      size="large"
                      value={locationCity}
                      onChange={(e) => setLocationCity(e.target.value)}
                    />
                    {isSubmit && !locationCity && (
                      <span style={{ color: "red" }}>Please enter city</span>
                    )}
                  </div>
                </Col>
                <Col md={8}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Enter State"
                      size="large"
                      value={locationState}
                      onChange={(e) => setLocationState(e.target.value)}
                    />
                    {isSubmit && !locationState && (
                      <span style={{ color: "red" }}>Please enter state</span>
                    )}
                  </div>
                </Col>

                <Col md={8}>
                  <div className="content-edit-company-input-wrapper">
                    <ZipCodeMask
                      value={locationZipCode}
                      onChange={(e) => setLocationZipCode(e.target.value)}
                    >
                      {() => {
                        <Input
                          placeholder="Enter Zip Code"
                          size="large"
                          value={locationZipCode}
                          onChange={(e) => setLocationZipCode(e.target.value)}
                        />;
                      }}
                    </ZipCodeMask>
                    {isSubmit && !locationZipCode && (
                      <span style={{ color: "red" }}>
                        Please enter zip code
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col md={12}>
                  <div className="content-edit-company-input-wrapper">
                    <PhoneMask
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    >
                      {() => {
                        <Input
                          size="large"
                          placeholder="(123) 456-7890"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />;
                      }}
                    </PhoneMask>
                    {isSubmit && !phone && (
                      <span style={{ color: "red" }}>
                        Please enter phone number
                      </span>
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="content-edit-company-input-wrapper">
                    <Input
                      placeholder="Email"
                      size="large"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {isEmailError && (
                      <span style={{ color: "red" }}>
                        Please enter valid email
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                {images.length
                  ? images.map((file, i) => (
                      <Col key={i} sm={12}>
                        <div className="uploaded-image-wrapper">
                          <img
                            src={
                              file?.originFileObj
                                ? URL.createObjectURL(file.originFileObj)
                                : file
                            }
                            alt={"Uploaded image"}
                          />
                        </div>
                      </Col>
                    ))
                  : null}

                <Col sm={12}>
                  <div
                    className="add-new-img"
                    onClick={() => setImageModal(true)}
                  >
                    <span>Add Image</span>
                    <button>Add</button>
                  </div>
                  {imageModal ? (
                    <ImageUploader
                      open={imageModal}
                      setOpen={setImageModal}
                      images={images}
                      setImages={(images) => {
                        setImages(images);
                      }}
                    />
                  ) : null}
                </Col>
              </Row>
            </div>
          </Spin>
        )}

        <div className="footer">
          <div className="footer-left"></div>
          <div className="footer-center"></div>
          <div className="footer-right">
            <div className="back-btn">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  if (
                    locationName &&
                    locationCity &&
                    locationState &&
                    locationZipCode &&
                    phone
                  ) {
                    setIsSubmit(false);
                    if (email) {
                      if (isValidEmail(email)) {
                        updateLocation();
                      } else {
                        setEmailError(true);
                      }
                    } else {
                      updateLocation();
                    }
                  } else {
                    setIsSubmit(true);
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <AddNewRoom
        open={isAddNewRoom}
        setOpen={setIsAddNewRoom}
        handleLocationModal={setOpen}
        file={file}
        locationAddress={locationAddress}
        locationName={locationName}
        setLocationName={setLocationName}
        setLocationAddress={setLocationAddress}
        locationCity={locationCity}
        setLocationCity={setLocationCity}
        locationState={locationState}
        setLocationState={setLocationState}
        getVenues={getVenues}
        locationZipCode={locationZipCode}
        setLocationZipCode={setLocationZipCode}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
      />
      <AddImage
        open={isAddImage}
        setOpen={setIsAddImage}
        openLocationModal={open}
        setOpenLocationModal={setOpen}
      />
      <MessageModal
        open={isSuccessModalOpen}
        setOpen={setIsSuccessModelOpen}
        content="Your Location has been added successfully"
      />
    </>
  );
};
export default EditLocation;
